
import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';
import {loftyLoginWithEmail, loftyCreateUser, loftyCreateResetToken, loftyResetPassword, loftyChangePassword, loftyUpdateUserInfo, loftyVerifyEmail} from '../../api/user.api';


import './createorder.scss';






const Pcreateorder = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  
  
  

  
  return (
    <div id="id64f0e9e79255464525649b2f" >

        <a
          target=""
          className=""
          id="i5127f"
          href="/"
        >
          
      <img
        className=""
        id="i6a797"
        src="https://assetsprojects.s3.amazonaws.com/405opfilm15nbvb.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i8j5" className="">
      
        <a
          target=""
          className=""
          id="idxbx"
          href="/"
        >
          
      <img
        className=""
        id="i3vl"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="i0njf" className="">
      
      <div id="ix7n" className="">
      
      <p className="" id="irt2n">
        <span>Dirección de Envío</span>
      </p>
      
      <div id="i5u8d" className="">
      
      </div>
      
      <div id="iosji" className="">
      
    <div>
      
      <form
        id="iuxmg"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					const loftyEmail = e.target.loftyEmail?.value;
					const loftyPassword = e.target.loftyPassword?.value;
					const res = await loftyCreateUser({
						loftyEmail: e.target.loftyEmail?.value,
						loftyName: e.target.loftyName?.value,
						loftyPassword: e.target.loftyPassword?.value,
					});
					addLoftyTokenApp({ loftytoken: res.token, loftyuser: res.user });
				} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
    <div id="i4q9t" className="">
    
      <label id="ihkb1" className="">
      <span>Nombre y Apellido *</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyName"
        type="text"
        placeholder=""
        className="fullWidth "
        id="iy239"
      />
      
    </div>
    
      <div id="ifct8" className="">
      
      <label id="i9ust" className="">
      <span>Teléfono</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyEmail"
        type="text"
        placeholder=""
        className="fullWidth "
        id="i3j5l"
      />
      
      </div>
      
      <div id="iaxa9" className="">
      
      <label id="io36a" className="">
      <span>Correo (Factura electrónica)</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyPassword"
        type="password"
        placeholder=""
        className="fullWidth "
        id="i2q5e"
      />
      
      </div>
      
      <div id="iwis4" className="">
      
      <div id="isqwd" className="">
      <span>Departamento</span>
      </div>
      
      <input
        name=""
        type="text"
        placeholder=""
        className="fullWidth "
        id="i1lhd"
      />
      
      </div>
      
      <div id="i6irs" className="">
      
      <div id="iqqb1" className="">
      <span>Ciudad</span>
      </div>
      
      <input
        name=""
        type="text"
        placeholder=""
        className="fullWidth "
        id="iypwn"
      />
      
      </div>
      
      <div id="i2wrs" className="">
      
      <div id="i8ayc" className="">
      <span>Dirección</span>
      </div>
      
      <input
        name=""
        type="text"
        placeholder=""
        className="fullWidth "
        id="i8m2c"
      />
      
      </div>
      
      <div id="iioz4" className="">
      
      <label id="" className="checkboxContainer ">
      
      <div id="idbcl" className="">
      <span>Hacer Predeterminado</span>
      </div>
      
      <span className="checkmark " id="">
        
      </span>
      
      </label>
      
      <label id="" className="checkboxContainer ">
      
      <div id="ixr2s" className="">
      <span>Recibir descuentos y Promociones al correo</span>
      </div>
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      <span className="checkmark " id="">
        
      </span>
      
      </label>
      
      </div>
      
      <div id="i0ax9" className="test ">
      
      </div>
      
      <div id="ikln6" className="test ">
      
      </div>
      
      </form>
      
    </div>
    
      </div>
      
      </div>
      
      </div>
      
      <div id="i8hrnl" className="">
      
      <div id="ix0scd" className="">
      
      <p className="" id="iqzlf">
        <span>Resumen</span>
      </p>
      
      <div id="iy57p" className="">
      
      </div>
      
      <div id="ihktr5" className="">
      
      <div id="i8ntp" className="">
      
      <div id="inywf" className="">
      
      <div id="i658l" className="">
      
      <p className="" id="i8qh6">
        <span>Sub Total</span>
      </p>
      
      </div>
      
      <div id="i8z0s" className="">
      
      <p className="" id="ibxk7">
        <span>L. 1,000.00</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iinous" className="">
      
      <div id="in6fec" className="">
      
      <p className="" id="ijzgf">
        <span>Envío</span>
      </p>
      
      </div>
      
      <div id="izlm47" className="">
      
      <p className="" id="iiyg7e">
        <span>L. 50.00</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i22xj" className="">
      
      <div id="i3fma" className="">
      
      <p className="" id="ibnr7">
        <span>ISV</span>
      </p>
      
      </div>
      
      <div id="iwlgl" className="">
      
      <p className="" id="ioog3">
        <span>L. 157.50</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i7c9b2" className="">
      
      <div id="i6773b" className="">
      
      <p className="" id="irt32n">
        <span>Total</span>
      </p>
      
      </div>
      
      <div id="irsmec" className="">
      
      <p className="" id="i5ddht">
        <span>L. 1,207.50</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iyvdus" className="">
      
        <a
          target=""
          className=""
          id="iru89x"
          href="#"
        >
          
      <p className="" id="igamwj">
        <span>Tarjeta de débito / crédito</span>
      </p>
      
        </a>
        
      </div>
      
      <div id="in730h" className="">
      
        <a
          target=""
          className=""
          id="iihz8e"
          href="#"
        >
          
      <p className="" id="ijh9xn">
        <span>Transferencia bancaria</span>
      </p>
      
        </a>
        
      </div>
      
      <div id="ivhdd7" className="">
      
        <a
          target=""
          className=""
          id="icss0d"
          href="#"
        >
          
      <p className="" id="i24mx4">
        <span>Efectivo</span>
      </p>
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pcreateorder);
  
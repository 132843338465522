
import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';
import {loftyLoginWithEmail, loftyCreateUser, loftyCreateResetToken, loftyResetPassword, loftyChangePassword, loftyUpdateUserInfo, loftyVerifyEmail} from '../../api/user.api';


import './signup.scss';






const Psignup = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  
  
  

  
  return (
    <div id="id64ef61ed9255464525648ddf" >

      <div id="i8j5" className="">
      
        <a
          target=""
          className=""
          id="idxbx"
          href="/"
        >
          
      <img
        className=""
        id="i3vl"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="ix7n" className="">
      
      <p className="" id="irt2n">
        <span>Crea Tu Cuenta</span>
      </p>
      
      <div id="i5u8d" className="">
      
      </div>
      
      <div id="imzrq" className="">
      
    <div>
      
      <form
        id="in0zk"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					const loftyEmail = e.target.loftyEmail?.value;
					const loftyPassword = e.target.loftyPassword?.value;
					const res = await loftyCreateUser({
						loftyEmail: e.target.loftyEmail?.value,
						phone: e.target.phone?.value,
						loftyName: e.target.loftyName?.value,
						loftyPassword: e.target.loftyPassword?.value,
					});
					addLoftyTokenApp({ loftytoken: res.token, loftyuser: res.user });
					window.location.href = '/';
				} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="ircvf" className="group-input ">
      
      <label id="i62tb" className="label-mixclo ">
      <span>Correo</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyEmail"
        type="text"
        placeholder="Correo"
        className="input-mixclo "
        id="ieupf"
      />
      
      </div>
      
      <div id="iq0fs" className="group-input ">
      
      <label id="itvgv" className="label-mixclo ">
      <span>Contraseña</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyPassword"
        type="password"
        placeholder="Contraseña"
        className="input-mixclo "
        id="iss72"
      />
      
      </div>
      
      <div id="iewch" className="group-input ">
      
      <label id="imdhi" className="label-mixclo ">
      <span>Nombre Completo*</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyName"
        type="text"
        placeholder="Nombre Completo"
        className="input-mixclo "
        id="ib40t"
      />
      
      </div>
      
      <div id="iiuoh" className="group-input ">
      
      <label id="i9i2t" className="label-mixclo ">
      <span>Telefono</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="phone"
        type="text"
        placeholder="99999999"
        className="input-mixclo "
        id="i01j9"
      />
      
      </div>
      
      <button
        type="submit"
        name=""
        id="i1uxk"
        className=""
        
      >
      
      <div id="ivlm3" className="">
      <span>Registrar</span>
      </div>
      
      </button>
      
      </form>
      
    </div>
    
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Psignup);
  
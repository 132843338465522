
import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './historyorderdetails.scss';






const Phistoryorderdetails = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  
  
  

  
  return (
    <div id="id65023d9b925546452564cb3f" >

        <a
          target=""
          className=""
          id="i2rp5q"
          href="/"
        >
          
      <img
        className=""
        id="i3u81m"
        src="https://assetsprojects.s3.amazonaws.com/405opfilm15nbvb.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i8j5" className="">
      
        <a
          target=""
          className=""
          id="idxbx"
          href="/"
        >
          
      <img
        className=""
        id="i3vl"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="iieo" className="">
      
      <div id="iffm5" className="gjs-row ">
      
      <div id="i59nf" className="gjs-cell ">
      
      <div id="id0p7" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      </label>
      
      <div id="i6ikd" className="">
      
      <img
        className=""
        id="i58ml"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      <div id="" className="checkboxMain ">
      
      </div>
      
      </div>
      
      <div id="izk0k" className="">
      
      <p className="" id="incag">
        
      <b className="" id="">
        <span>Camiseta Polo</span>
      </b>
      
      </p>
      
      <p className="" id="i2o52">
        
      <b className="" id="">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ieb5lc" className="gjs-cell ">
      
      <div id="ik06ye" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      </label>
      
      <div id="ixj8z3" className="">
      
      <img
        className=""
        id="ivfxwf"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      <div id="" className="checkboxMain ">
      
      </div>
      
      </div>
      
      <div id="i2ymvh" className="">
      
      <p className="" id="itaax6">
        
      <b className="" id="">
        <span>Camiseta Polo</span>
      </b>
      
      </p>
      
      <p className="" id="i05bom">
        
      <b className="" id="">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ic22jk" className="gjs-cell ">
      
      <div id="iq0dsz" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      </label>
      
      <div id="ioadht" className="">
      
      <img
        className=""
        id="iz83ck"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      <div id="" className="checkboxMain ">
      
      </div>
      
      </div>
      
      <div id="igbvtj" className="">
      
      <p className="" id="ivr1ut">
        
      <b className="" id="">
        <span>Camiseta Polo</span>
      </b>
      
      </p>
      
      <p className="" id="i48t35">
        
      <b className="" id="">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i6bdqa" className="gjs-cell ">
      
      <div id="il5nuj" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      </label>
      
      <div id="id1unb" className="">
      
      <img
        className=""
        id="inx4kf"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      <div id="" className="checkboxMain ">
      
      </div>
      
      </div>
      
      <div id="ikwro1" className="">
      
      <p className="" id="i5fmqb">
        
      <b className="" id="">
        <span>Camiseta Polo</span>
      </b>
      
      </p>
      
      <p className="" id="ieawoi">
        
      <b className="" id="">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="icg36o" className="gjs-cell ">
      
      <div id="iuoakz" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      </label>
      
      <div id="i1cjw" className="">
      
      <img
        className=""
        id="ixdik"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      <div id="" className="checkboxMain ">
      
      </div>
      
      </div>
      
      <div id="ibpdi" className="">
      
      <p className="" id="i348z">
        
      <b className="" id="">
        <span>Camiseta Polo</span>
      </b>
      
      </p>
      
      <p className="" id="i211e">
        
      <b className="" id="">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iiexj" className="gjs-cell ">
      
      <div id="i4kft" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      </label>
      
      <div id="irj7j" className="">
      
      <img
        className=""
        id="ino9i"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      <div id="" className="checkboxMain ">
      
      </div>
      
      </div>
      
      <div id="iq8g9" className="">
      
      <p className="" id="iu7fh">
        
      <b className="" id="">
        <span>Camiseta Polo</span>
      </b>
      
      </p>
      
      <p className="" id="ibkif">
        
      <b className="" id="">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i29ru" className="">
      
      <div id="ihow92" className="">
      
      <div id="i4q4qw" className="">
      
      <div id="irxlfd" className="">
      
      <p className="" id="i0o2rh">
        
      <b className="" id="iztzok">
        <span>ID de Orden</span>
      <br className="" id="" />
      <span>8Cab23655X29x</span>
      </b>
      
      </p>
      
      <p className="" id="igbicw">
        
      <b className="" id="">
        <span>Subtotal:</span>
      <br className="" id="" />
      <span>L. 2994.00</span>
      <br className="" id="" />
      
      </b>
      
      </p>
      
      <p className="" id="ibwcdk">
        
      <b className="" id="i4lpu8">
        <span>Envío:</span>
      <br className="" id="" />
      <span>L. 50.00</span>
      <br className="" id="" />
      
      </b>
      
      </p>
      
      <p className="" id="iddz9v">
        
      <b className="" id="il3egh">
        <span>Subtotal:</span>
      <br className="" id="" />
      <span>L. 3044.00</span>
      <br className="" id="" />
      
      </b>
      
      </p>
      
      </div>
      
      <div id="if9all" className="">
      
      </div>
      
      <div id="if643m" className="">
      
      <p className="" id="iu364h">
        
      <b className="" id="">
        <span>COMPRAR</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Phistoryorderdetails);
  

import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {getOnestore } from '../../api/store.api'
import {getAllproducts } from '../../api/products.api'

import './gestorproductos.scss';






const Pgestorproductos = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  	const [loftyendpoint_65a069c8ddfeab4572d73f92, setLoftyEndpoint_65a069c8ddfeab4572d73f92] = useState({});
		const [loftyendpoint_651b485265f10c3079b66356, setLoftyEndpoint_651b485265f10c3079b66356] = useState([]);
	
  useEffect(() => {
		const loftyHandleData = async () => {
			const loftyres_65a069c8ddfeab4572d73f92 = await getOnestore({ _id: match.params.id, token: loftytoken || 'asdsa' });
			setLoftyEndpoint_65a069c8ddfeab4572d73f92(loftyres_65a069c8ddfeab4572d73f92.data);
			const loftyres_651b485265f10c3079b66356 = await getAllproducts({ token: loftytoken || 'asdsa' });
			setLoftyEndpoint_651b485265f10c3079b66356(loftyres_651b485265f10c3079b66356.data);
		}
		loftyHandleData();
	}, []);

  
  
  
  

  if (!loftytoken) {
		return (<div>Requiere Autenticación</div>);
	}

  return (
    <div id="id651b449d925546452564e17e" >

        <a
          target=""
          className=""
          id="i2rp5q"
          href="/"
        >
          
      <img
        className=""
        id="i3u81m"
        src="https://assetsprojects.s3.amazonaws.com/405opfilm15nbvb.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i8j5" className="">
      
        <a
          target=""
          className=""
          id="idxbx"
          href="/"
        >
          
      <img
        className=""
        id="i3vl"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="iieo" className="">
      
      <div id="i0pp2m" className="">
      
        <div id="ie4ceo" className="">
        
        <div className="" id="idqp7d">
          
          <div className="" id="is82wp">
            {loftyendpoint_65a069c8ddfeab4572d73f92.name}
          </div>
          
        </div>
        
        </div>
        
      <p className="" id="i5yes5">
        <span>Productos</span>
      </p>
      
      </div>
      
      <div id="ita536" className="">
      
        <div id="iwgp7k" className="">
        
        <div className="" id="iaam5e">
        {
          loftyendpoint_651b485265f10c3079b66356.map((loftyitem) => (
            <div key={loftyitem._id} className="">
              
      <div id="ib1fbf" className="">
      
      <div id="i6ikd" className="">
      
        <img
          className=""
          id="ieut1k"
          alt=""
          src={loftyendpoint_651b485265f10c3079b66356.loftyUrl}
        />
        
      <div id="" className="checkboxMain ">
      
      </div>
      
      </div>
      
      <div id="izk0k" className="">
      
        <div className="" id="iw2l3i">
          
          <div className="" id="i7mscp">
            {loftyitem.name}
          </div>
          
        </div>
        
        <div className="" id="ir4mtg">
          
          <div className="" id="">
            {loftyitem.sku}
          </div>
          
        </div>
        
        <div className="" id="i7t90x">
          
          <div className="" id="">
            {loftyitem.price}
          </div>
          
        </div>
        
      </div>
      
      <div id="id0p7" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      </label>
      
        <a
          target=""
          className=""
          id="i7w5kh"
          href={`/company/update/${loftyitem}/null`}
        >
          
        <a
          target=""
          className=""
          id="iqlm6"
          href={`/company/update/null/null`}
        >
          
      <div id="if643m" className="">
      
      <p className="" id="iu364h">
        
      <b className="" id="i8aotq">
        <span>Editar</span>
      </b>
      
      </p>
      
      </div>
      
        </a>
        
        </a>
        
      <div id="ik1lkk" className="">
      
      <p className="" id="iesjzy">
        
      <b className="" id="ijtm4g">
        <span>Eliminar</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
            </div>
          ))
        }
        </div>
        
        </div>
        
      </div>
      
      </div>
      
      <div id="i29ru" className="">
      
        <div id="i6q1e" className="">
        
      <div id="ihow92" className="">
      
      <div id="i4q4qw" className="">
      
        <a
          target=""
          className=""
          id="i24ta"
          href={`/company/producto/add/null`}
        >
          
      <div id="i30s02" className="">
      
      <p className="" id="i8qbpo">
        
      <b className="" id="">
        <span>Agregar Producto</span>
      </b>
      
      </p>
      
      </div>
      
        </a>
        
        <a
          target=""
          className=""
          id="itd3t"
          href={`/company/orders/null`}
        >
          
      <div id="it5tsh" className="">
      
      <p className="" id="ifm2ek">
        
      <b className="" id="i7v16h">
        <span>Ordenes</span>
      </b>
      
      </p>
      
      </div>
      
        </a>
        
      </div>
      
      </div>
      
        </div>
        
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pgestorproductos);
  
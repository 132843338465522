
import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';
import {loftyLoginWithEmail, loftyCreateUser, loftyCreateResetToken, loftyResetPassword, loftyChangePassword, loftyUpdateUserInfo, loftyVerifyEmail} from '../../api/user.api';


import './resetpassword.scss';






const Presetpassword = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  
  
  

  
  return (
    <div id="id64f2653b925546452564a4af" >

      <div id="i8j5" className="">
      
        <a
          target=""
          className=""
          id="i4udd"
          href="/"
        >
          
      <img
        className=""
        id="i3b78"
        src="https://assetsprojects.s3.amazonaws.com/405opfilm155pk3.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="idxbx"
          href="/"
        >
          
      <img
        className=""
        id="i3vl"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="ix7n" className="">
      
      <p className="" id="irt2n">
        <span>Cambiar Contraseña</span>
      </p>
      
      <div id="i5u8d" className="">
      
      </div>
      
      <div id="iosji" className="">
      
    <div>
      
      <form
        id="iuxmg"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					const loftyEmail = e.target.loftyEmail?.value;
					const loftyPassword = e.target.loftyPassword?.value;
					const res = await loftyCreateUser({
					});
					addLoftyTokenApp({ loftytoken: res.token, loftyuser: res.user });
				} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="" className="passContainer ">
      
      <div id="irwdl" className="">
      <span>Contraseña Anterior</span>
      </div>
      
      <input
        name=""
        type="password"
        placeholder=""
        className="passwordInput "
        id=""
      />
      
      </div>
      
      <div id="" className="passContainer ">
      
      <div id="ikk5y" className="">
      <span>Nueva Contraseña</span>
      </div>
      
      <input
        name=""
        type="password"
        placeholder=""
        className="passwordInput "
        id=""
      />
      
      </div>
      
      <div id="" className="passContainer ">
      
      <div id="isqzs" className="">
      <span>Repetir Contraseña</span>
      </div>
      
      <input
        name=""
        type="password"
        placeholder=""
        className="passwordInput "
        id=""
      />
      
      </div>
      
      <div id="i0ax9" className="test ">
      
      </div>
      
      <div id="ikln6" className="test ">
      
      </div>
      
      <button
        type="submit"
        name=""
        id="i32ol"
        className=""
        
      >
      <span>Crear Cuenta</span>
      </button>
      
      </form>
      
    </div>
    
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Presetpassword);
  

import axios from '../config/axios';

export const url = '/api/contactovo6eh'


export const createcontactovo6eh = ({
  token,
	nombredelatienda8pvrr,
	correoelectrnicoe1wldl,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token && nombredelatienda8pvrr && correoelectrnicoe1wldl ) {

    axios.post(`${url}//create/basic/66673b6ba2fa806a8b0cafad`, {
		nombredelatienda8pvrr,
		correoelectrnicoe1wldl,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!nombredelatienda8pvrr) {
		reject({
			status: 'error',
			info: 'nombredelatienda8pvrr no esta definido',
		});
	}  else if (!correoelectrnicoe1wldl) {
		reject({
			status: 'error',
			info: 'correoelectrnicoe1wldl no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      


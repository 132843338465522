
import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './ordenesempresa.scss';






const Pordenesempresa = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  
  
  

  
  return (
    <div id="id651b4b7d925546452564e32a" >

        <a
          target=""
          className=""
          id="i2rp5q"
          href="/company/product"
        >
          
      <img
        className=""
        id="i3u81m"
        src="https://assetsprojects.s3.amazonaws.com/405opfilm15nbvb.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i8j5" className="">
      
        <a
          target=""
          className=""
          id="idxbx"
          href="/"
        >
          
      <img
        className=""
        id="i3vl"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="iieo" className="">
      
      <div id="imnu59" className="">
      
      <p className="" id="ichskd">
        <span>Empresa</span>
      </p>
      
      <p className="" id="iprmbx">
        <span>Ordenes</span>
      </p>
      
      </div>
      
      <div id="iffm5" className="gjs-row ">
      
      <div id="i59nf" className="gjs-cell ">
      
      <div id="id0p7" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      </label>
      
      <div id="i6ikd" className="">
      
      <div id="" className="swiper mySwiper ">
      
      <div id="" className="swiper-wrapper ">
      
      <div id="i4olc" className="swiper-slide ">
      
      <img
        className=""
        id="i58ml"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iy5bh" className="swiper-slide ">
      
      <p className="" id="ivhi1">
        <span>Slide 2</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="" className="swiper-button-next ">
      
      </div>
      
      <div id="" className="swiper-button-prev ">
      
      </div>
      
      <div id="" className="swiper-pagination ">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="izk0k" className="">
      
      <p className="" id="incag">
        
      <b className="" id="i8wkg">
        <span>Id de Orden</span>
      <br className="" id="" />
      
      </b>
      
      </p>
      
      <p className="" id="ilt6wm">
        
      <b className="" id="iut4pq">
        <span>8cac82600a26dad</span>
      </b>
      
      </p>
      
      <p className="" id="iapmzw">
        
      <b className="" id="iwwqhe">
        <span>Total</span>
      </b>
      
      </p>
      
      <p className="" id="i2o52">
        
      <b className="" id="in0ry">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      <div id="ipd7ig" className="">
      
      <button
        type=""
        name=""
        id="i3pubb"
        className=""
        
      >
      
      <div id="i83qvt" className="">
      <span>Ver Detalles</span>
      </div>
      
      </button>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i7crag" className="gjs-cell ">
      
      <div id="itprc1" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      </label>
      
      <div id="ih9we8" className="">
      
      <div id="" className="swiper mySwiper ">
      
      <div id="" className="swiper-wrapper ">
      
      <div id="i9tvwh" className="swiper-slide ">
      
      <img
        className=""
        id="iexnra"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iq61m7" className="swiper-slide ">
      
      <p className="" id="ijcnpi">
        <span>Slide 2</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="" className="swiper-button-next ">
      
      </div>
      
      <div id="" className="swiper-button-prev ">
      
      </div>
      
      <div id="" className="swiper-pagination ">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i0m9az" className="">
      
      <p className="" id="ix4h5f">
        
      <b className="" id="iewmof">
        <span>Id de Orden</span>
      <br className="" id="" />
      
      </b>
      
      </p>
      
      <p className="" id="iyls7p">
        
      <b className="" id="ipr1ry">
        <span>8cac82600a26dad</span>
      </b>
      
      </p>
      
      <p className="" id="ilq90j">
        
      <b className="" id="igpntj">
        <span>Total</span>
      </b>
      
      </p>
      
      <p className="" id="ivnd78">
        
      <b className="" id="i7vhdh">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      <div id="iud7nj" className="">
      
      <button
        type=""
        name=""
        id="iikxhh"
        className=""
        
      >
      
      <div id="ippw5k" className="">
      <span>Ver Detalles</span>
      </div>
      
      </button>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iarrfo" className="gjs-cell ">
      
      <div id="ijjfz2" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      </label>
      
      <div id="ivoa6l" className="">
      
      <div id="" className="swiper mySwiper ">
      
      <div id="" className="swiper-wrapper ">
      
      <div id="ij5zxu" className="swiper-slide ">
      
      <img
        className=""
        id="imhlfz"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="iigi3i" className="swiper-slide ">
      
      <p className="" id="iz5noj">
        <span>Slide 2</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="" className="swiper-button-next ">
      
      </div>
      
      <div id="" className="swiper-button-prev ">
      
      </div>
      
      <div id="" className="swiper-pagination ">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="imo62f" className="">
      
      <p className="" id="i040b2">
        
      <b className="" id="ilm6lf">
        <span>Id de Orden</span>
      <br className="" id="" />
      
      </b>
      
      </p>
      
      <p className="" id="ib5oth">
        
      <b className="" id="im46kc">
        <span>8cac82600a26dad</span>
      </b>
      
      </p>
      
      <p className="" id="i2nuux">
        
      <b className="" id="ihi8w4">
        <span>Total</span>
      </b>
      
      </p>
      
      <p className="" id="iws7ck">
        
      <b className="" id="ifdbm8">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      <div id="ifsgri" className="">
      
      <button
        type=""
        name=""
        id="ip511y"
        className=""
        
      >
      
      <div id="i542ll" className="">
      <span>Ver Detalles</span>
      </div>
      
      </button>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="izj4sw" className="gjs-cell ">
      
      <div id="ikk86e" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      </label>
      
      <div id="iv65g1" className="">
      
      <div id="" className="swiper mySwiper ">
      
      <div id="" className="swiper-wrapper ">
      
      <div id="i1h4kg" className="swiper-slide ">
      
      <img
        className=""
        id="ipi2eg"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i32skc" className="swiper-slide ">
      
      <p className="" id="i03peo">
        <span>Slide 2</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="" className="swiper-button-next ">
      
      </div>
      
      <div id="" className="swiper-button-prev ">
      
      </div>
      
      <div id="" className="swiper-pagination ">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iwdyke" className="">
      
      <p className="" id="iy7opj">
        
      <b className="" id="ivl89z">
        <span>Id de Orden</span>
      <br className="" id="" />
      
      </b>
      
      </p>
      
      <p className="" id="i341nr">
        
      <b className="" id="img2vu">
        <span>8cac82600a26dad</span>
      </b>
      
      </p>
      
      <p className="" id="iethpr">
        
      <b className="" id="i2m3kh">
        <span>Total</span>
      </b>
      
      </p>
      
      <p className="" id="i9n58d">
        
      <b className="" id="i9r5m6">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      <div id="i2p7hl" className="">
      
      <button
        type=""
        name=""
        id="itk3oh"
        className=""
        
      >
      
      <div id="ios82j" className="">
      <span>Ver Detalles</span>
      </div>
      
      </button>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iqt1j1" className="gjs-cell ">
      
      <div id="ia80bk" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      </label>
      
      <div id="iyh5j1" className="">
      
      <div id="" className="swiper mySwiper ">
      
      <div id="" className="swiper-wrapper ">
      
      <div id="ivsb4r" className="swiper-slide ">
      
      <img
        className=""
        id="ij3rbd"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i6l8tf" className="swiper-slide ">
      
      <p className="" id="i1j53k">
        <span>Slide 2</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="" className="swiper-button-next ">
      
      </div>
      
      <div id="" className="swiper-button-prev ">
      
      </div>
      
      <div id="" className="swiper-pagination ">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="imj3y3" className="">
      
      <p className="" id="iqjt4x">
        
      <b className="" id="ijjftw">
        <span>Id de Orden</span>
      <br className="" id="" />
      
      </b>
      
      </p>
      
      <p className="" id="ifspym">
        
      <b className="" id="iyqj2j">
        <span>8cac82600a26dad</span>
      </b>
      
      </p>
      
      <p className="" id="iz93q3">
        
      <b className="" id="ijvqjg">
        <span>Total</span>
      </b>
      
      </p>
      
      <p className="" id="il7mnq">
        
      <b className="" id="ijw9i5">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      <div id="i3pdsz" className="">
      
      <button
        type=""
        name=""
        id="i0ykif"
        className=""
        
      >
      
      <div id="iyd27t" className="">
      <span>Ver Detalles</span>
      </div>
      
      </button>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pordenesempresa);
  

import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './acuerdosservicios.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pacuerdosservicios = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id6542e0c7c239522da2e3ace2" >

      <div id="i25m" className="">
      
      <div id="iq9l" className="">
      
        <a
          target=""
          className=""
          id="ify7b"
          href="#"
        >
          
      <img
        className=""
        id="iss9f"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i8eor" className="">
      
        <a
          target=""
          className=""
          id="iucyx"
          href="#"
        >
          
      <img
        className=""
        id="i73es"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcriat3.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="i9p3l" className="">
      
        <a
          target=""
          className=""
          id="ijvrd"
          href="#"
        >
          
      <img
        className=""
        id="il6cc"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrlf8y.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="iq338"
          href="#"
        >
          
      <img
        className=""
        id="i9sfh"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrlnfs.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i76os"
          href="#"
        >
          
      <img
        className=""
        id="i9obh"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrlxk6.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="iyz7i" className="">
      
        <a
          target=""
          className=""
          id="irruz"
          href="#"
        >
          
      <img
        className=""
        id="ixp2y"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrmopk.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="ib43" className="">
      
      <div id="i1au" className="">
      
      <div id="ihmgh" className="">
      
        <a
          target=""
          className=""
          id="ig0z1"
          href="/signin"
        >
          
      <img
        className=""
        id="ivg2s"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcs69zg.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="ikbwp"
          href="#"
        >
          
        </a>
        
      <div id="i8iqk" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>About</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Team</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Contact</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Twitter</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ievj" className="">
      
      <div id="ivoo4" className="">
      
      <p className="" id="ipcv9">
        <span>Acuerdos de Prestación de Servicios para Proveedores</span>
      </p>
      
      <p className="" id="ic1ow">
        
      <p className="MsoNormal " id="ilsii">
        
      <b className="" id="">
        
      <span className="" id="">
        <span>1. OBJETO</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="ilcyc">
        
      <span className="" id="">
        <span>El Cliente busca utilizar los servicios de Mixclo Style como un Marketplace en línea para vender sus productos y prendas de vestir.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ilys7">
        
      <span className="" id="">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="id74t">
        
      <b className="" id="">
        
      <span className="" id="">
        <span>2. SERVICIOS PRESTADOS POR EL PROVEEDOR DE SERVICIOS</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="ipuwd">
        
      <span className="" id="">
        <span>2.1. Mixclo Style proporcionará al Cliente una plataforma en línea para listar y vender sus productos.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ii0nd">
        
      <span className="" id="">
        <span>2.2. El Cliente tiene la opción de enviar su inventario al almacén de Mixclo Style si así lo desea.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="id1yzs">
        
      <span className="" id="">
        <span>Nota: Al hacerlo, se fomenta la venta de sus prendas, ya que los consumidores podrán crear conjuntos con ellas y realizar un solo pago por envío, lo que promoverá la venta de sus productos.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i99fic">
        
      <span className="" id="">
        <span>2.3. Mixclo Style proveerá de Marketing publicitario para sus productos atrayendo mas clientes para su posible compra.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ivf03e">
        
      <span className="" id="">
        <span>2.4. Mixclo Style proporcionara la logística adecuada para el envío de los productos del cliente.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ilpsk5">
        
      <span className="" id="">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i13cq3">
        
      <b className="" id="">
        
      <span className="" id="">
        <span>3. PERIODO DE PRUEBA GRATUITA</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="i14nn9">
        
      <span className="" id="">
        <span>El Cliente tendrá derecho a un período de prueba gratuito de 15 días para utilizar la plataforma de Mixclo Style sin costo alguno. Durante este período de prueba, el Cliente podrá explorar y evaluar la plataforma antes de tomar una decisión sobre la tarifa a elegir.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i1thlz">
        
      <span className="" id="">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ibdrqx">
        
      <b className="" id="">
        
      <span className="" id="">
        <span>4. DURACIÓN DEL ACUERDO</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="irfues">
        
      <span className="" id="">
        <span>Este Acuerdo entrará en vigor en la fecha de aceptación por parte del Cliente y continuará en vigor hasta que cualquiera de las partes lo rescinda mediante notificación por escrito con un preaviso de 30 días.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i7f7q4">
        
      <span className="" id="">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="idefid">
        
      <b className="" id="">
        
      <span className="" id="">
        <span>5. PAGO DE TARIFAS</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="imzcmc">
        
      <span className="" id="">
        <span>5.1. Después del período de prueba gratuito, el Cliente se compromete a pagar las tarifas de acuerdo con la opción elegida en el momento de la inscripción.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ikgk0l">
        
      <span className="" id="">
        <span>5.2. El Cliente podrá elegir entre dos opciones de tarifas para utilizar los servicios de Mixclo Style:</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iwt05k">
        
      <span className="" id="">
        <span>   a) Tarifa por Comisión: El Cliente pagará una comisión de 30 lempiras por cada prenda vendida a través de la plataforma de Mixclo Style.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="izm9x8">
        
      <span className="" id="">
        <span>   b) Tarifa de Suscripción Mensual: El Cliente pagará una tarifa de suscripción mensual de 650 lempiras para vender sus prendas a través de Mixclo Style.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iyky57">
        
      <span className="" id="">
        <span>5.3. El Cliente proporcionará a Mixclo Style un número de cuenta bancaria al cual depositar sus ganancias.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ic6whr">
        
      <span className="" id="">
        <span>5.4. El Proveedor de Servicios facturará al Cliente de acuerdo con la tarifa seleccionada.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="itoy5l">
        
      <span className="" id="">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iox47q">
        
      <b className="" id="">
        
      <span className="" id="">
        <span>6. RESPONSABILIDADES DEL CLIENTE</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="iwxxok">
        
      <span className="" id="">
        <span>6.1. El cliente deberá proporcionar la información que se le solicite dentro de los límites legales y de la plataforma Mixclo Style, con el fin de brindar una mejor experiencia de compra-venta entre vendedor y consumidor.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ibwsrh">
        
      <span className="" id="">
        <span>6.2. El Cliente es responsable de proporcionar descripciones precisas de sus productos, gestionar sus listados, mantener el control de su inventario, actualizando regularmente las existencias de sus productos y cumplir con todas las leyes y regulaciones aplicables en relación con la venta de productos en la plataforma de Mixclo Style.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i118pc">
        
      <span className="" id="">
        <span>6.3. El cliente acepta que Mixmatch Clothing se reserva el derecho de modificar la plataforma de Mixclo Style con el fin del mejoramiento constante de la misma para brindar una mejor experiencia tanto para vendedores como para consumidores.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="irmweu">
        
      <span className="" id="">
        <span>6.4. El cliente en lo que respecta a su inventario:</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i5qaas">
        
      <span className="" id="">
        <span>a) Podrá solamente subir prendas de vestir en estado de NUEVO.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="imr9j8">
        
      <span className="" id="">
        <span>b) No deberá violar derechos de autor de terceros que tengan derechos sobre una marca, en caso de que venda productos de marcas de terceros deberá tener el permiso para ello, o autenticar la compra que hizo a una tienda autorizada para su reventa mediante un recibo.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="itde1i">
        
      <span className="" id="">
        <span>c) El cliente podrá vender prendas de marcas de su autoría siempre que estas estén en estado de NUEVO.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i0udth">
        
      <span className="" id="">
        <span>d) El cliente deberá tener en stock y en todo momento las prendas que suba en la plataforma Mixclo Style.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iqsz8k">
        
      <span className="" id="">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ip6ert">
        
      <b className="" id="">
        
      <span className="" id="">
        <span>7. TERMINACIÓN</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="imnkof">
        
      <span className="" id="">
        <span>Cualquiera de las partes podrá dar por terminado este Acuerdo mediante notificación por escrito con un preaviso de 30 días. El Proveedor de Servicios se reserva el derecho de suspender o dar por terminado el acceso del Cliente a la plataforma en caso de incumplimiento grave de los términos de este Acuerdo.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i2mdug">
        
      <span className="" id="">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ib8o2p">
        
      <span className="" id="">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ik48el">
        
      <b className="" id="">
        
      <span className="" id="">
        <span>8.</span>
      </span>
      
      </b>
      
      <span className="" id="">
        
      <b className="" id="">
        <span>CONFIDENCIALIDAD</span>
      </b>
      
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i1szfe">
        
      <span className="" id="">
        <span>Ambas partes se comprometen a mantener la confidencialidad de cualquier información confidencial compartida en virtud de este Acuerdo.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ifpq6l">
        
      <span className="" id="">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="izslyk">
        
      <b className="" id="">
        
      <span className="" id="">
        <span>9. LEY APLICABLE Y JURISDICCIÓN</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="ingzw6">
        
      <span className="" id="">
        <span>Este Acuerdo se regirá e interpretará de acuerdo con las leyes de la República de Honduras y cualquier disputa que surja en relación con este Acuerdo se resolverá ante los tribunales competentes en la misma jurisdicción.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="in38vg">
        
      <span className="" id="">
        <span>Al aceptar este Acuerdo, el Cliente reconoce haber leído, comprendido y aceptado todos los términos y condiciones establecidos en el presente documento.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pacuerdosservicios);
  

import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {getOnestore } from '../../api/store.api'
import {createproducts } from '../../api/products.api'

import './agregarproducto.scss';






const Pagregarproducto = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  	const [loftyendpoint_65a069c8ddfeab4572d73f92, setLoftyEndpoint_65a069c8ddfeab4572d73f92] = useState({});
	
  useEffect(() => {
		const loftyHandleData = async () => {
			const loftyres_65a069c8ddfeab4572d73f92 = await getOnestore({ _id: match.params.idcompany, token: loftytoken || 'asdsa' });
			setLoftyEndpoint_65a069c8ddfeab4572d73f92(loftyres_65a069c8ddfeab4572d73f92.data);
		}
		loftyHandleData();
	}, []);

  
  
  
  

  if (!loftytoken) {
		return (<div>Requiere Autenticación</div>);
	}

  return (
    <div id="id651b49bf925546452564e26a" >

      <div id="i8j5" className="">
      
      <div id="i5t8i" className="">
      
        <div id="i6q89" className="">
        
        <a
          target=""
          className=""
          id="if5kr"
          href={`/company/product/null`}
        >
          
      <img
        className=""
        id="inabg"
        src="https://assetsprojects.s3.amazonaws.com/405opfilm155pk3.png"
        alt="undefined"
      />
      
        </a>
        
        </div>
        
      </div>
      
      <div id="ifidw" className="">
      
      <img
        className=""
        id="iitlh"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
      </div>
      
        <a
          target=""
          className=""
          id="idxbx"
          href="/"
        >
          
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="i0njf" className="">
      
      <div id="ix7n" className="">
      
      <p className="" id="irt2n">
        <span>Agregando Producto</span>
      </p>
      
      <div id="i5u8d" className="">
      
      </div>
      
      <div id="iosji" className="">
      
      <div id="ir27j" className="">
      
        <div className="" id="isgp8">
          
      <form
        id="iiupk"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					const loftyFile = e.target.fileBucket.files[0];
			createproducts({
						name: e.target.name.value,
						sku: e.target.sku.value,
						price: e.target.price.value,
						type: e.target.type.value,
						category: e.target.category.value,
					});
					alert("Se agrego de manera exitosa el registro");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="isa6k" className="">
      
      <label id="" className="">
      <span>Nombre</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="name"
        type="text"
        placeholder="Nombre"
        className="form-control "
        id="i1fg8"
      />
      
      </div>
      
      <div id="ia0qs" className="">
      
      <label id="" className="">
      <span>SKU</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="sku"
        type="text"
        placeholder="SKU"
        className="form-control "
        id="i66pf"
      />
      
      </div>
      
      <div id="ihqea" className="">
      
      <label id="" className="">
      <span>Precio</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="price"
        type="number"
        placeholder="Precio"
        className="form-control "
        id="imjy2"
      />
      
      </div>
      
      <div id="ip51l3" className="">
      
      <label id="" className="">
      <span>Tipo</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="type"
        type="text"
        placeholder="top | medium | lower"
        className="form-control "
        id="ilxlse"
      />
      
      </div>
      
      <div id="i4g6y9" className="">
      
      <label id="" className="">
      <span>Categoria</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="category"
        type="text"
        placeholder="Categoria"
        className="form-control "
        id="iy7uc2"
      />
      
      </div>
      
      <div id="iaspwi" className="">
      
      <label id="irp11h" className="">
      <span>Imagen de producto</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="fileBucket"
        type="file"
        placeholder=""
        className=""
        id="ixqoaf"
      />
      
      </div>
      
      <div id="i9a2p" className="">
      
      <button
        type="submit"
        name=""
        id="ihd7m6"
        className="button-submit "
        
      >
      
      <div id="izp1be" className="">
      <span>Agregar</span>
      </div>
      
      </button>
      
      </div>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pagregarproducto);
  

import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';
import {loftyLoginWithEmail, loftyCreateUser, loftyCreateResetToken, loftyResetPassword, loftyChangePassword, loftyUpdateUserInfo, loftyVerifyEmail} from '../../api/user.api';

import {getAllstore } from '../../api/store.api'

import './updateprofile.scss';






const Pupdateprofile = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  	const [loftyendpoint_65b93201ddfeab4572d75a34, setLoftyEndpoint_65b93201ddfeab4572d75a34] = useState([]);
	
  useEffect(() => {
		const loftyHandleData = async () => {
			const loftyres_65b93201ddfeab4572d75a34 = await getAllstore({ token: loftytoken || 'asdsa' });
			setLoftyEndpoint_65b93201ddfeab4572d75a34(loftyres_65b93201ddfeab4572d75a34.data);
		}
		loftyHandleData();
	}, []);

  
  
  
  

  
  return (
    <div id="id651b3ff0925546452564e049" >

        <a
          target=""
          className=""
          id="i5127f"
          href="/"
        >
          
      <img
        className=""
        id="i6a797"
        src="https://assetsprojects.s3.amazonaws.com/405opfilm15nbvb.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i8j5" className="">
      
        <a
          target=""
          className=""
          id="idxbx"
          href="/"
        >
          
      <img
        className=""
        id="i3vl"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="i0njf" className="">
      
      <div id="ix7n" className="">
      
      <p className="" id="irt2n">
        <span>Actualiza tu información</span>
      </p>
      
      <div id="i5u8d" className="">
      
      </div>
      
      <div id="iosji" className="">
      
      <form
        id="icmxa"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
				} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="iljwh" className="grupo-inputs ">
      
      <label id="ii85v" className="label-input ">
      <span>Nombre y Apellido *</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyName"
        type="text"
        placeholder="Nombre completo"
        className="input-mixclo "
        id="i8gio"
      />
      
      </div>
      
      <div id="i9mif" className="grupo-inputs ">
      
      <label id="iucl8" className="label-input ">
      <span>Teléfono</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="phone"
        type="text"
        placeholder="Teléfono"
        className="input-mixclo "
        id="ib672"
      />
      
      </div>
      
      <div id="iye43" className="">
      
      <button
        type="submit"
        name=""
        id="i47yq"
        className=""
        
      >
      
      <div id="ihqid" className="">
      <span>Actualizar Informacion</span>
      </div>
      
      </button>
      
      </div>
      
      </form>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i8hrnl" className="">
      
      <div id="ix0scd" className="">
      
      <p className="" id="iqzlf">
        <span>Inscripción de Empresa</span>
      </p>
      
      <div id="iy57p" className="">
      
      </div>
      
      <div id="ihktr5" className="">
      
      <div id="i8ntp" className="">
      
      <div id="ir7fvj" className="">
      
      <p className="" id="izc21">
        <span>Deseas registrar tu empresa en Mixclo Style, da clic en el botón de abajo y llena el formulario.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ivhdd7" className="">
      
        <a
          target=""
          className=""
          id="icss0d"
          href="/company/form"
        >
          
      <p className="" id="i24mx4">
        <span>Registrar empresa</span>
      </p>
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iod4j" className="">
      
        <div id="ieqif" className="">
        
        <div className="" id="iyu74">
        {
          loftyendpoint_65b93201ddfeab4572d75a34.map((loftyitem) => (
            <div key={loftyitem._id} className="">
              
      <div id="ios9o" className="">
      
      <div id="iekjv" className="">
      
      <img
        className=""
        id="i123f"
        src="https://assetsprojects.s3.amazonaws.com/406racils0n69mx.png"
        alt="undefined"
      />
      
      </div>
      
        <div className="" id="i3lfk">
          
          <div className="" id="ititg">
            {loftyitem.name}
          </div>
          
        </div>
        
        <div className="" id="iefsx">
          
          <div className="" id="">
            {loftyitem.rtn}
          </div>
          
        </div>
        
      <div id="idgg2" className="">
      
        <a
          target=""
          className="button-mixclo "
          id="i07ml"
          href="#"
        >
          
    <div id="ieuw5" className="">
    <span>Ver</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
            </div>
          ))
        }
        </div>
        
        </div>
        
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pupdateprofile);
  

import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';
import {loftyLoginWithEmail, loftyCreateUser, loftyCreateResetToken, loftyResetPassword, loftyChangePassword, loftyUpdateUserInfo, loftyVerifyEmail} from '../../api/user.api';


import './signin.scss';






const Psignin = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  
  
  

  
  return (
    <div id="id64dd2d649255464525647cb2" >

      <div id="i8j5" className="">
      
        <a
          target=""
          className=""
          id="i4ca4"
          href="/"
        >
          
      <img
        className=""
        id="i3vl"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="ix7n" className="">
      
      <div id="iosji" className="">
      
    <div>
      
      <p className="" id="irt2n">
        <span>Iniciar Sesión</span>
      </p>
      
      <form
        id="iuxdl"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					const loftyEmail = e.target.loftyEmail?.value;
					const loftyPassword = e.target.loftyPassword?.value;
					const res = await loftyLoginWithEmail({ loftyEmail, loftyPassword })
					addLoftyTokenApp({ loftytoken: res.token, loftyuser: res.user });
					window.location.href = '/';
				} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="iqs9f" className="">
      
      <label id="i6h3f" className="">
      <span>Correo Electrónico</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyEmail"
        type="text"
        placeholder=""
        className="input-mixclo "
        id="iox85"
      />
      
      </div>
      
      <div id="iousf" className="">
      
      <label id="ilyti" className="">
      <span>Contraseña</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyPassword"
        type="password"
        placeholder=""
        className="input-mixclo "
        id="i4g29"
      />
      
      </div>
      
      <button
        type="submit"
        name=""
        id="iedkh"
        className=""
        
      >
      <span>Iniciar Sesión</span>
      </button>
      
      <div id="i80xk" className="">
      
      </div>
      
      <p className="" id="iyb74">
        <span>¿Es tu primera vez aquí?</span>
      </p>
      
        <a
          target=""
          className=""
          id="i64nj"
          href="/signup"
        >
          
      <div id="ia12i" className="">
      <span>Crear una Cuenta</span>
      </div>
      
        </a>
        
      <button
        type=""
        name=""
        id="ibkrl"
        className=""
        
      >
      
      </button>
      
      </form>
      
    </div>
    
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Psignin);
  

import axios from '../config/axios';

export const url = '/api/products'


export const createproducts = ({
  token,
	name,
	sku,
	havediscount,
	price,
	idstore,
	pricediscount,
	type,
	category,
	status,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token && name && sku && price && idstore && type && category ) {
		const formData = new FormData();
		formData.append('loftyFile', loftyFile);
		formData.append('name', name);
		formData.append('sku', sku);
		formData.append('havediscount', havediscount);
		formData.append('price', price);
		formData.append('idstore', idstore);
		formData.append('pricediscount', pricediscount);
		formData.append('type', type);
		formData.append('category', category);
		formData.append('status', status);

    axios.post(`${url}//create/basic/651b484b65f10c3079b6633c`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',

        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!name) {
		reject({
			status: 'error',
			info: 'name no esta definido',
		});
	}  else if (!sku) {
		reject({
			status: 'error',
			info: 'sku no esta definido',
		});
	}  else if (!price) {
		reject({
			status: 'error',
			info: 'price no esta definido',
		});
	}  else if (!idstore) {
		reject({
			status: 'error',
			info: 'idstore no esta definido',
		});
	}  else if (!type) {
		reject({
			status: 'error',
			info: 'type no esta definido',
		});
	}  else if (!category) {
		reject({
			status: 'error',
			info: 'category no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      
export const updateproducts = ({
  token,
  _id,
	name,
	sku,
	havediscount,
	price,
	idstore,
	pricediscount,
	type,
	category,
	status,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token && _id && name && sku && price && idstore && type && category ) {
    axios.post(`${url}//update/basic/651b484d65f10c3079b66349/${_id}`, {
		name,
		sku,
		havediscount,
		price,
		idstore,
		pricediscount,
		type,
		category,
		status,

    }, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else if (!name) {
		reject({
			status: 'error',
			info: 'name no esta definido',
		});
	}  else if (!sku) {
		reject({
			status: 'error',
			info: 'sku no esta definido',
		});
	}  else if (!price) {
		reject({
			status: 'error',
			info: 'price no esta definido',
		});
	}  else if (!idstore) {
		reject({
			status: 'error',
			info: 'idstore no esta definido',
		});
	}  else if (!type) {
		reject({
			status: 'error',
			info: 'type no esta definido',
		});
	}  else if (!category) {
		reject({
			status: 'error',
			info: 'category no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      
export const getAllproducts = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}//list/basic/651b485265f10c3079b66356`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      



import axios from '../config/axios';

export const url = '/api/store'


export const createstore = ({
  token,
	name,
	userid,
	status,
	country,
	state,
	city,
	address,
	rtn,
	email,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token && name && userid && country && state && city && address && rtn && email ) {
		const formData = new FormData();
		formData.append('loftyFile', loftyFile);
		formData.append('name', name);
		formData.append('userid', userid);
		formData.append('status', status);
		formData.append('country', country);
		formData.append('state', state);
		formData.append('city', city);
		formData.append('address', address);
		formData.append('rtn', rtn);
		formData.append('email', email);

    axios.post(`${url}//create/basic/651b410365f10c3079b662dd`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',

        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!name) {
		reject({
			status: 'error',
			info: 'name no esta definido',
		});
	}  else if (!userid) {
		reject({
			status: 'error',
			info: 'userid no esta definido',
		});
	}  else if (!country) {
		reject({
			status: 'error',
			info: 'country no esta definido',
		});
	}  else if (!state) {
		reject({
			status: 'error',
			info: 'state no esta definido',
		});
	}  else if (!city) {
		reject({
			status: 'error',
			info: 'city no esta definido',
		});
	}  else if (!address) {
		reject({
			status: 'error',
			info: 'address no esta definido',
		});
	}  else if (!rtn) {
		reject({
			status: 'error',
			info: 'rtn no esta definido',
		});
	}  else if (!email) {
		reject({
			status: 'error',
			info: 'email no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      
export const getOnestore = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}//one/basic/65a069c8ddfeab4572d73f92/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      
export const getAllstore = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}//list/basic/65b93201ddfeab4572d75a34`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      



import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './carruceles.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pcarruceles = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id65c87b14b0184704f908ff00" >

      <div id="i25m" className="">
      
      <div id="iq9l" className="">
      
        <a
          target=""
          className=""
          id="ify7b"
          href="#"
        >
          
      <img
        className=""
        id="iss9f"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i8eor" className="">
      
        <a
          target=""
          className=""
          id="iucyx"
          href={`/cart`}
        >
          
      <img
        className=""
        id="i73es"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcriat3.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="i9p3l" className="">
      
        <a
          target=""
          className=""
          id="ijvrd"
          href="#"
        >
          
      <img
        className=""
        id="il6cc"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrlf8y.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="iq338"
          href="#"
        >
          
      <img
        className=""
        id="i9sfh"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrlnfs.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i76os"
          href="#"
        >
          
      <img
        className=""
        id="i9obh"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrlxk6.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="iyz7i" className="">
      
        <a
          target=""
          className=""
          id="irruz"
          href="#"
        >
          
      <img
        className=""
        id="ixp2y"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrmopk.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="ib43" className="">
      
      <div id="i1au" className="">
      
      <div id="ihmgh" className="">
      
        <a
          target=""
          className=""
          id="ig0z1"
          href="/signin"
        >
          
      <img
        className=""
        id="ivg2s"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcs69zg.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="ikbwp"
          href="#"
        >
          
        </a>
        
      <div id="i8iqk" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>About</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Team</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Contact</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Twitter</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ievj" className="">
      
      <div id="ivoo4" className="">
      
      <div id="i99nx" className="">
      
      <div id="" className="swiper gjs-three-swiper-1 ">
      
      <div id="" className="swiper-wrapper ">
      
        <div className="swiper-slide item-threecarrusel1 " id="">
        {
          /* loftyendpoint_undefined.map((loftyitem) => (
            <div key={loftyitem._id} className="swiper-slide item-threecarrusel1 ">
              
        <div className="" id="ilq6k">
          
      <div id="izqpn" className="">
      <span>
         {{ name }}
        </span>
      </div>
      
        </div>
        
            </div>
          )) */
        }
        </div>
        
      </div>
      
      <div id="" className="gjs-swiper-button-next1 ">
      
      </div>
      
      <div id="" className="gjs-swiper-button-prev1 ">
      
      </div>
      
      </div>
      
      <div id="" className="gjs-three-swiper-2 swiper ">
      
      <div id="" className="swiper-wrapper ">
      
        <div className="swiper-slide item-threecarrusel2 " id="">
        {
          /* loftyendpoint_undefined.map((loftyitem) => (
            <div key={loftyitem._id} className="swiper-slide item-threecarrusel2 ">
              
        <div className="" id="i9jci">
          
      <div id="" className="">
      <span>
         {{ name }}
        </span>
      </div>
      
        </div>
        
            </div>
          )) */
        }
        </div>
        
      </div>
      
      <div id="" className="gjs-swiper-button-next2 ">
      
      </div>
      
      <div id="" className="gjs-swiper-button-prev2 ">
      
      </div>
      
      </div>
      
      <div id="" className="swiper gjs-three-swiper-3 ">
      
      <div id="" className="swiper-wrapper ">
      
        <div className="swiper-slide item-threecarrusel3 " id="">
        {
          /* loftyendpoint_undefined.map((loftyitem) => (
            <div key={loftyitem._id} className="swiper-slide item-threecarrusel3 ">
              
        <div className="" id="ijsmh">
          
      <div id="" className="">
      <span>
         {{ name }}
        </span>
      </div>
      
        </div>
        
            </div>
          )) */
        }
        </div>
        
      </div>
      
      <div id="" className="gjs-swiper-button-next3 ">
      
      </div>
      
      <div id="" className="gjs-swiper-button-prev3 ">
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pcarruceles);
  
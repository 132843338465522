
import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AOS from 'aos';
import {connect} from 'react-redux';
import {addLoftyUser, deleteLoftytoken} from './actions/auth.actions';
import { loftyGetLoggedUser } from './api/user.api';

// pages
import mainlayout from './pages/mainlayout/mainlayout'
import signin from './pages/signin/signin'
import signup from './pages/signup/signup'
import onorder from './pages/onorder/onorder'
import createorder from './pages/createorder/createorder'
import cart from './pages/cart/cart'
import userinfo from './pages/userinfo/userinfo'
import resetpassword from './pages/resetpassword/resetpassword'
import orderlist from './pages/orderlist/orderlist'
import historyorderdetails from './pages/historyorderdetails/historyorderdetails'
import updateprofile from './pages/updateprofile/updateprofile'
import formcompany from './pages/formcompany/formcompany'
import gestorproductos from './pages/gestorproductos/gestorproductos'
import agregarproducto from './pages/agregarproducto/agregarproducto'
import updateproduct from './pages/updateproduct/updateproduct'
import ordenesempresa from './pages/ordenesempresa/ordenesempresa'
import terminoscondiciones from './pages/terminoscondiciones/terminoscondiciones'
import politicas from './pages/politicas/politicas'
import acuerdosservicios from './pages/acuerdosservicios/acuerdosservicios'
import carruceles from './pages/carruceles/carruceles'
import landinginicialwnxeil from './pages/landinginicialwnxeil/landinginicialwnxeil'


// css
import './App.css';
import 'aos/dist/aos.css';

AOS.init();

function initSwiper() {
  // eslint-disable-next-line no-undef
  const swiper = new Swiper('.mySwiper', {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  // console.log('swiper :>> ', swiper);
}

const App = ({loftytoken, addLoftyUserApp, deleteLoftytokenApp}) => {

  // agregando iniciación para el Swipper
  useEffect(() => {
    setTimeout(() => {
      initSwiper();
    }, 2000);
  }, []);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await loftyGetLoggedUser({ token: loftytoken });
        addLoftyUserApp(res.user);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        deleteLoftytokenApp();
      }
    }
    handleData();
  }, []);
  if (loading) {
    return <div>Loading ...</div>
  }


  return (
    <Router>
      <Switch>
			<Route exact path="/home2" component={mainlayout} />
			<Route exact path="/signin" component={signin} />
			<Route exact path="/signup" component={signup} />
			<Route exact path="/order/:id" component={onorder} />
			<Route exact path="/buy" component={createorder} />
			<Route exact path="/cart" component={cart} />
			<Route exact path="/userinfo" component={userinfo} />
			<Route exact path="/resetpassword" component={resetpassword} />
			<Route exact path="/orderlist" component={orderlist} />
			<Route exact path="/order/:orderid" component={historyorderdetails} />
			<Route exact path="/updateprofile" component={updateprofile} />
			<Route exact path="/company/form" component={formcompany} />
			<Route exact path="/company/product/:id" component={gestorproductos} />
			<Route exact path="/company/producto/add/:idcompany" component={agregarproducto} />
			<Route exact path="/company/update/:product/:idcompany" component={updateproduct} />
			<Route exact path="/company/orders/:tienda" component={ordenesempresa} />
			<Route exact path="/terminos" component={terminoscondiciones} />
			<Route exact path="/politicas" component={politicas} />
			<Route exact path="/acuerdos" component={acuerdosservicios} />
			<Route exact path="/carruceles" component={carruceles} />
			<Route exact path="/" component={landinginicialwnxeil} />

      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addLoftyUserApp: (user) => dispatch(addLoftyUser(user)),
  deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);


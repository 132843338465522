
import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';
import {loftyLoginWithEmail, loftyCreateUser, loftyCreateResetToken, loftyResetPassword, loftyChangePassword, loftyUpdateUserInfo, loftyVerifyEmail} from '../../api/user.api';


import './userinfo.scss';






const Puserinfo = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  
  
  

  
  return (
    <div id="id64f242d6925546452564a160" >

      <div id="i8j5" className="">
      
        <a
          target=""
          className=""
          id="i4udd"
          href="/"
        >
          
      <img
        className=""
        id="i3b78"
        src="https://assetsprojects.s3.amazonaws.com/405opfilm155pk3.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="idxbx"
          href="/"
        >
          
      <img
        className=""
        id="i3vl"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="ix7n" className="">
      
      <p className="" id="irt2n">
        <span>Información del Usuario</span>
      </p>
      
      <div id="i5u8d" className="">
      
      </div>
      
      <div id="iosji" className="">
      
    <div>
      
      <form
        id="iuxmg"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					const loftyEmail = e.target.loftyEmail?.value;
					const loftyPassword = e.target.loftyPassword?.value;
					const res = await loftyCreateUser({
						loftyEmail: e.target.loftyEmail?.value,
						loftyName: e.target.loftyName?.value,
					});
					addLoftyTokenApp({ loftytoken: res.token, loftyuser: res.user });
				} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
    <div id="i4q9t" className="">
    
      <label id="ihkb1" className="">
      <span>Nombre y Apellido *</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyName"
        type="text"
        placeholder=""
        className="fullWidth "
        id="iy239"
      />
      
    </div>
    
      <div id="ifct8" className="">
      
      <label id="i9ust" className="">
      <span>Correo Electrónico *</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="loftyEmail"
        type="text"
        placeholder=""
        className="fullWidth "
        id="i3j5l"
      />
      
      </div>
      
      <div id="iwis4" className="">
      
      <div id="isqwd" className="">
      <span>Departamento</span>
      </div>
      
      <input
        name=""
        type="text"
        placeholder=""
        className="fullWidth "
        id="i1lhd"
      />
      
      </div>
      
      <div id="i6irs" className="">
      
      <div id="iqqb1" className="">
      <span>Ciudad</span>
      </div>
      
      <input
        name=""
        type="text"
        placeholder=""
        className="fullWidth "
        id="iypwn"
      />
      
      </div>
      
      <div id="iioz4" className="">
      
      <label id="" className="checkboxContainer ">
      
      <div id="idbcl" className="">
      <span>Recibir descuentos y promociones al correo</span>
      </div>
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      <span className="checkmark " id="">
        
      </span>
      
      </label>
      
      <label id="" className="checkboxContainer ">
      
      <div id="ixr2s" className="">
      <span>Hacer predeterminado</span>
      </div>
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      <span className="checkmark " id="">
        
      </span>
      
      </label>
      
      </div>
      
      <div id="i0ax9" className="test ">
      
      </div>
      
      <div id="ikln6" className="test ">
      
      </div>
      
      <button
        type="submit"
        name=""
        id="i32ol"
        className=""
        
      >
      <span>Crear Cuenta</span>
      </button>
      
      </form>
      
    </div>
    
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Puserinfo);
  

import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {getOnestore } from '../../api/store.api'

import './updateproduct.scss';






const Pupdateproduct = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  	const [loftyendpoint_65a069c8ddfeab4572d73f92, setLoftyEndpoint_65a069c8ddfeab4572d73f92] = useState({});
	
  useEffect(() => {
		const loftyHandleData = async () => {
			const loftyres_65a069c8ddfeab4572d73f92 = await getOnestore({ _id: match.params.idcompany, token: loftytoken || 'asdsa' });
			setLoftyEndpoint_65a069c8ddfeab4572d73f92(loftyres_65a069c8ddfeab4572d73f92.data);
		}
		loftyHandleData();
	}, []);

  
  
  
  

  
  return (
    <div id="id651b4a0c925546452564e2ac" >

      <div id="i8j5" className="">
      
      <div id="ipzsz" className="">
      
        <div id="ihatk" className="">
        
        <a
          target=""
          className=""
          id="ij6a1"
          href={`/company/product/null`}
        >
          
      <img
        className=""
        id="ijz7l"
        src="https://assetsprojects.s3.amazonaws.com/405opfilm155pk3.png"
        alt="undefined"
      />
      
        </a>
        
        </div>
        
      </div>
      
      <div id="is4c8" className="">
      
      <img
        className=""
        id="im59h"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
      </div>
      
        <a
          target=""
          className=""
          id="idxbx"
          href="/"
        >
          
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="i0njf" className="">
      
      <div id="ix7n" className="">
      
      <p className="" id="irt2n">
        <span>Actualizar Producto</span>
      </p>
      
      <div id="i5u8d" className="">
      
      </div>
      
      <div id="iosji" className="">
      
      <div id="ir27j" className="">
      
    <div id="ia4iv" className="">
    
      <form
        id="irv57"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
				} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="iva1s" className="">
      
      <label id="" className="">
      <span>Nombre</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="name"
        type="text"
        placeholder="Nombre"
        className="form-input "
        id="ii6aa"
      />
      
      </div>
      
      <div id="iis0i" className="">
      
      <label id="" className="">
      <span>SKU</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="sku"
        type="text"
        placeholder="SKU"
        className="form-input "
        id="it23r"
      />
      
      </div>
      
      <div id="iqzop" className="">
      
      <label id="" className="">
      <span>Precio</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="price"
        type="number"
        placeholder="Precio"
        className="form-input "
        id="itk95"
      />
      
      </div>
      
      <div id="i5n5u2" className="">
      
      <label id="" className="">
      <span>Tipo</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="type"
        type="text"
        placeholder="top | medium | lower"
        className="form-input "
        id="irxvia"
      />
      
      </div>
      
      <div id="iz0a3b" className="">
      
      <label id="" className="">
      <span>Categoria</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="category"
        type="text"
        placeholder="Categoria"
        className="form-input "
        id="iqvb8h"
      />
      
      </div>
      
      <div id="iuzffu" className="">
      
      <label id="" className="">
      <span>Estatus</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="status"
        type="text"
        placeholder="Estatus"
        className="form-input "
        id="inpn6f"
      />
      
      </div>
      
      <div id="ik44kc" className="">
      
      <label id="" className="">
      <span>Upload File</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="fileBucket"
        type="file"
        placeholder=""
        className=""
        id="icsqrp"
      />
      
      </div>
      
      <div id="ivi8q" className="">
      
      <button
        type="submit"
        name=""
        id="ipp4z"
        className="button-submit "
        
      >
      
      <div id="iif2n" className="">
      <span>Actualizar</span>
      </div>
      
      </button>
      
      </div>
      
      </form>
      
    </div>
    
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pupdateproduct);
  
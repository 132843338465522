
import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './onorder.scss';






const Ponorder = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  
  
  

  
  return (
    <div id="id64f0e1f292554645256498e5" >

        <a
          target=""
          className=""
          id="i6ath"
          href="/"
        >
          
      <img
        className=""
        id="ia8ak"
        src="https://assetsprojects.s3.amazonaws.com/405opfilm155pk3.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i8j5" className="">
      
        <a
          target=""
          className=""
          id="ir0og"
          href="/"
        >
          
      <img
        className=""
        id="i3vl"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="iwct3" className="">
      
      <div id="iylc" className="">
      
      <div id="ig68r" className="">
      
      <p className="" id="i6op">
        <span>Tu pedido ha sido procesado con exito gracias!</span>
      </p>
      
      <div id="ittys" className="">
      
      <div id="ijwdc" className="">
      
      <div id="iigmf" className="">
      
      <p className="" id="ihlsu">
        <span>Nombre</span>
      </p>
      
      </div>
      
      <div id="idhpi" className="">
      
      <p className="" id="imaa6">
        <span>Harold Chirinos</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iqnbs" className="">
      
      <div id="ijnbt" className="">
      
      <p className="" id="izgqe">
        <span>Sub Total</span>
      </p>
      
      </div>
      
      <div id="i9lmm" className="">
      
      <p className="" id="idzrd">
        <span>L. 2,994.00</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i65up" className="">
      
      <div id="ioj6b" className="">
      
      <p className="" id="iryht">
        <span>Envío</span>
      </p>
      
      </div>
      
      <div id="iqimt" className="">
      
      <p className="" id="ivnwf">
        <span>L. 50.00</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i04jo" className="">
      
      <div id="ir42k" className="">
      
      <p className="" id="ii85h">
        <span>ISV</span>
      </p>
      
      </div>
      
      <div id="ihpxh" className="">
      
      <p className="" id="ieatd">
        <span>L. 100.00</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      <div id="ihv65" className="">
      
      <div id="ia44n" className="">
      
      <p className="" id="igdp9">
        <span>Total</span>
      </p>
      
      </div>
      
      <div id="ipc7s" className="">
      
      <p className="" id="ietuj">
        <span>L. 3,044.00</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <p className="" id="i4f68">
        <span>* Se enviará la factura de compra a tu correo.</span>
      </p>
      
      </div>
      
      <div id="in0o1v" className="">
      
        <a
          target=""
          className=""
          id="ify10o"
          href="/"
        >
          
    <div id="im4v9s" className="">
    <span>Seguir creando Conjuntos</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Ponorder);
  
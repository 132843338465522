
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {createcontactovo6eh } from '../../api/contactovo6eh.api'

import './landinginicialwnxeil.scss';






const Plandinginicialwnxeil = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);

  
  
  
  

  
  return (
    <div id="id6667350f914da0d427fd81df" >

      <div id="iw4j-2" className="">
      
      <div id="ips2" className="">
      
      <div id="itmdc" className="">
      
      <img
        className=""
        id="ismeq"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx98m5gm.jpg"
        alt="undefined"
      />
      
      </div>
      
      <img
        className=""
        id="iok6z3"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx98m5gm.jpg"
        alt="undefined"
      />
      
      </div>
      
      <div id="ihk8" className="">
      
      <div id="it6xg" className="">
      
      <p className="" id="ip3sl">
        
    <div id="ipazry" className="">
    <span>Haz crecer tu negocio de moda</span>
      <br className="" id="" />
      <span>con nuestra</span>
      <br className="" id="" />
      <span>Plataforma</span>
    </div>
    
      </p>
      
      <p className="" id="i803s">
        <span>¡Permite a tus clientes crear conjuntos con tu inventario!</span>
      </p>
      
      <div id="iqulzk" className="">
      
        <a
          target=""
          className="button-negro "
          id="i894r1"
          href="#il6v7o"
        >
          
    <div id="im9zh1" className="">
    <span>Regístrate</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      <div id="if5pi" className="">
      
      <img
        className=""
        id="iz9d4"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx98ow06.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <p className="" id="igqyi">
        <span>¡Descubre el Marketplace del Futuro!</span>
      <br className="" id="" />
      
      </p>
      
      <p className="" id="inczd9">
        <span>Brindales a tus clientes una experiencia de compra personalizada y atractiva, aumentando la visibilidad de tus productos y fomentando la interacción y el compromiso con tu marca. Únete a nosotros hoy y descubre cómo Mix-tyle puede transformar tu negocio de moda en línea.</span>
      </p>
      
      <div id="i45acb" className="">
      
      <div id="ivn0rj" className="card beneficios ">
      
      <div id="iasc88" className="">
      
      </div>
      
      <p className="" id="inihm4">
        <span>Beneficios</span>
      </p>
      
      <div id="iere45" className="">
      
      <div id="ipwk2h" className="">
      
      <div id="iq0ljb" className="">
      
      <img
        className=""
        id="i8666j"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx996mdr.png"
        alt="undefined"
      />
      
      <p className="" id="itnnb6">
        <span>Perfil propio de tu tienda</span>
      </p>
      
      </div>
      
      <p className="descripcionbeneficios " id="ibvnx4">
        <span>Crea un perfil en Mix-tyle para identificar tu tienda y determinar tus términos y condiciones.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ig4oya" className="">
      
      <div id="icpcmy" className="">
      
      <div id="i34m0y" className="">
      
      <img
        className=""
        id="imrgtj"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx996mdr.png"
        alt="undefined"
      />
      
      <p className="" id="ixiso7">
        <span>Gestiona tu inventario</span>
      </p>
      
      </div>
      
      <p className="descripcionbeneficios " id="i2rc6j">
        <span>Mantén un control preciso de tus productos, asegurando que siempre tengas stock disponible y actualizado.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ivealj" className="">
      
      <div id="i0232x" className="">
      
      <div id="i0lx9g" className="">
      
      <img
        className=""
        id="iamojv"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx996mdr.png"
        alt="undefined"
      />
      
      <p className="" id="ihgu5b">
        <span>Monitoreo de ventas</span>
      </p>
      
      </div>
      
      <p className="descripcionbeneficios " id="ictur7">
        <span>Realiza un seguimiento detallado de tus ventas para identificar tendencias y mejorar estrategias.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="inuxel" className="">
      
      <div id="ihx88g" className="">
      
      <div id="ilzmny" className="">
      
      <img
        className=""
        id="i62zee"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx996mdr.png"
        alt="undefined"
      />
      
      <p className="" id="itd2pp">
        <span>Monitoreo de envíos</span>
      </p>
      
      </div>
      
      <p className="descripcionbeneficios " id="ieqxum">
        <span>Superviza el estado de tus envíos e tiempo real para proporcionar a tus clientes atualizaciones precisas y mejorar su satisfacción.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iq76ui" className="">
      
      <div id="im6yvr" className="">
      
      <div id="igha3f" className="">
      
      <img
        className=""
        id="ilr1xw"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx996mdr.png"
        alt="undefined"
      />
      
      <p className="" id="iuoucx">
        <span>Venta cruzada</span>
      </p>
      
      </div>
      
      <p className="descripcionbeneficios " id="ib43hi">
        <span>Incrementa el valor de tus ventas por medio de prendas y productos complementarios a la compra, que les pueden interesar a tus clientes.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="isnlda" className="">
      
      <div id="ih6dpf" className="">
      
      <div id="impl3g" className="">
      
      <img
        className=""
        id="iboty1"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx996mdr.png"
        alt="undefined"
      />
      
      <p className="" id="igqagk">
        <span>Marketing y promoción</span>
      </p>
      
      </div>
      
      <p className="descripcionbeneficios " id="iqhhr5">
        <span>Mix-tyle promocionará el contenido de tu tienda para atraerte consumidores.</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i3dh" className="">
      
      <div id="ifg5ok" className="">
      
      <div id="i402eg" className="card comision ">
      
      <div id="if39u3" className="">
      
      </div>
      
      <p className="" id="iikwdr">
        <span>Costo</span>
      </p>
      
      <p className="" id="irwzmu">
        <span>Comisión por venta</span>
      <br className="" id="" />
      <span>solo del 12%</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <p className="" id="iyijx2">
        <span>¡Ya tenemos todo listo!</span>
      <br className="" id="" />
      
      </p>
      
      <p className="" id="iicawo">
        <span>¿Quieres formar parte? Regístrate y te notificaremos sobre cuando puedes empezar.</span>
      </p>
      
      <p className="" id="ijfni9">
        <span>Deja la información de tu tienda a continuación.</span>
      </p>
      
      <div id="ieud0c" className="">
      
      <div id="il6v7o" className="card contacto ">
      
        <div className="" id="irny3g">
          
      <form
        id="if5wu3"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createcontactovo6eh({
						nombredelatienda8pvrr: e.target.nombredelatienda8pvrr.value,
						correoelectrnicoe1wldl: e.target.correoelectrnicoe1wldl.value,
					});
					alert("Se agrego de manera exitosa el registro");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="i7wydp" className="">
      
      <label id="ij1c4p" className="">
      <span>Nombre de tu tienda</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="nombredelatienda8pvrr"
        type="text"
        placeholder=""
        className="input "
        id="iaivkn"
      />
      
      </div>
      
      <div id="i8e7dl" className="">
      
      <label id="ik25ps" className="">
      <span>Correo electrónico</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="correoelectrnicoe1wldl"
        type="text"
        placeholder=""
        className="input "
        id="itc009"
      />
      
      </div>
      
      <div id="inmvli" className="">
      
      <button
        type="submit"
        name=""
        id="iw7tqn"
        className="button-negro botonful "
        
      >
      
      <div id="itexsa" className="">
      <span>Enviar</span>
      </div>
      
      </button>
      
      </div>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      <div id="icohi" className="">
      
      <div id="iou1pv" className="">
      
      <p className="" id="izq2dp">
        <span>© 2024 Mix-tyle. Todos los derechos reservados.</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Plandinginicialwnxeil);
  

import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './politicas.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Ppoliticas = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id6542e01cc239522da2e3aca6" >

      <div id="i25m" className="">
      
      <div id="iq9l" className="">
      
        <a
          target=""
          className=""
          id="ify7b"
          href="#"
        >
          
      <img
        className=""
        id="iss9f"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i8eor" className="">
      
        <a
          target=""
          className=""
          id="iucyx"
          href="#"
        >
          
      <img
        className=""
        id="i73es"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcriat3.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="i9p3l" className="">
      
        <a
          target=""
          className=""
          id="ijvrd"
          href="#"
        >
          
      <img
        className=""
        id="il6cc"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrlf8y.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="iq338"
          href="#"
        >
          
      <img
        className=""
        id="i9sfh"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrlnfs.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i76os"
          href="#"
        >
          
      <img
        className=""
        id="i9obh"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrlxk6.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="iyz7i" className="">
      
        <a
          target=""
          className=""
          id="irruz"
          href="#"
        >
          
      <img
        className=""
        id="ixp2y"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrmopk.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="ib43" className="">
      
      <div id="i1au" className="">
      
      <div id="ihmgh" className="">
      
        <a
          target=""
          className=""
          id="ig0z1"
          href="/signin"
        >
          
      <img
        className=""
        id="ivg2s"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcs69zg.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="ikbwp"
          href="#"
        >
          
        </a>
        
      <div id="i8iqk" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>About</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Team</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Contact</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Twitter</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ievj" className="">
      
      <div id="ivoo4" className="">
      
      <p className="" id="ipcv9">
        <span>Aviso de Politicas de Privacidad</span>
      </p>
      
      <p className="" id="iho4a">
        
      <p className="MsoNormal " id="ijwdx">
        
      <span className="" id="iy67i">
        <span>Fecha de vigencia: [Fecha de vigencia]</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i5j7v">
        
      <span className="" id="igye5">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iqcju">
        
      <span className="" id="in4zx">
        <span>Este Aviso de Política de Privacidad describe cómo [Nombre de la Empresa] ("nosotros", "nuestro", o "la Compañía") recopila, utiliza y comparte información personal cuando usted visita nuestro sitio web [www.ejemplo.com] (el "Sitio"). Al acceder o utilizar el Sitio, usted acepta las prácticas de privacidad descritas en este aviso.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="insth">
        
      <span className="" id="iqpsh">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i8c4m">
        
      <b className="" id="">
        
      <span className="" id="i0s6q">
        <span> Información que Recopilamos</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="i5t3g">
        
      <b className="" id="">
        
      <span className="" id="iymth">
        
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="ipvvc">
        
      <b className="" id="">
        
      <span className="" id="iyahp">
        <span>Información que Usted Nos Proporciona</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="i2f4ot">
        
      <span className="" id="ikgm1g">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i705jx">
        
      <span className="" id="isrb44">
        <span>Cuando utiliza el Sitio, es posible que nos proporcione información personal, como su nombre, dirección de correo electrónico, dirección postal, número de teléfono y otra información de contacto.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ih112i">
        
      <span className="" id="iqqm4l">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ic45mk">
        
      <b className="" id="">
        
      <span className="" id="in7otb">
        <span>Información de Registro</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="i4ibum">
        
      <span className="" id="i2us5f">
        <span>Recopilamos información sobre su actividad en el Sitio, como las páginas que visita y las acciones que realiza.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="io4kj5">
        
      <span className="" id="iukewc">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i3o02k">
        
      <b className="" id="">
        
      <span className="" id="iday5x">
        <span>Información de Cookies y Tecnologías Similares</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="ite4g3">
        
      <span className="" id="ivyzvf">
        <span>Utilizamos cookies y tecnologías similares para recopilar información sobre su navegación por el Sitio.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ixrkrf">
        
      <span className="" id="ieasqf">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="istwzk">
        
      <span className="" id="ihf5vc">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i2tp6j">
        
      <b className="" id="">
        
      <span className="" id="i7qbks">
        <span>Cómo Utilizamos Su Información</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="if6qiy">
        
      <span className="" id="ib9l1d">
        <span>Utilizamos la información que recopilamos para:</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iazd9a">
        
      <span className="" id="iatc6h">
        <span>- Proporcionar y mantener el Sitio.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ipnvkb">
        
      <span className="" id="ipkzva">
        <span>- Responder a sus consultas, comentarios o preguntas.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="imbzqk">
        
      <span className="" id="is19b9">
        <span>- Enviarle comunicaciones promocionales y actualizaciones, si ha optado por recibirlas.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i0gc8b">
        
      <span className="" id="i6pecc">
        <span>- Realizar análisis y mejorar el Sitio.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iv1489">
        
      <span className="" id="ixnkbz">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iza0l2">
        
      <b className="" id="">
        
      <span className="" id="ioc4ig">
        <span>Compartir Su Información</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="inwgi9">
        
      <span className="" id="irp48t">
        <span>No compartimos su información personal con terceros, excepto cuando sea necesario para cumplir con fines legales o regulaciones aplicables.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="irb07r">
        
      <span className="" id="i1ddrf">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="itf7f6">
        
      <b className="" id="">
        
      <span className="" id="iwxw4b">
        <span>Enlaces a Sitios Web de Terceros</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="i5cuq3">
        
      <span className="" id="iad8y2">
        <span>Nuestro Sitio puede contener enlaces a sitios web de terceros. No tenemos control sobre estos sitios web y no somos responsables de sus prácticas de privacidad. Le recomendamos que revise las políticas de privacidad de esos sitios web.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ibqyi6">
        
      <span className="" id="iib5vv">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="it4b6k">
        
      <b className="" id="">
        
      <span className="" id="ieffcm">
        <span>Seguridad de la Información</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="iddeot">
        
      <span className="" id="iwqyoo">
        <span>Tomamos medidas para proteger su información personal, pero no podemos garantizar su seguridad. Debe tomar precauciones adicionales al compartir información en línea.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="in8esf">
        
      <span className="" id="ium5xi">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i1w2fi">
        
      <b className="" id="">
        
      <span className="" id="idd29h">
        <span>Sus Derechos de Privacidad</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="itj0rk">
        
      <span className="" id="ietz03">
        <span>Usted tiene derechos sobre su información personal, que incluyen:</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ii7irh">
        
      <span className="" id="ilff8u">
        <span>- Acceder a su información personal.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ial5y4">
        
      <span className="" id="iadyij">
        <span>- Corregir su información personal.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ijjbxg">
        
      <span className="" id="ilhx2r">
        <span>- Eliminar su información personal.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iz44d5">
        
      <span className="" id="iyb1eo">
        <span>- Oponerse al procesamiento de su información personal.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iemmoq">
        
      <span className="" id="ighoan">
        <span>Para ejercer estos derechos o si tiene alguna pregunta sobre nuestra política de privacidad, contáctenos a través de [correo electrónico de contacto].</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="imsen7">
        
      <span className="" id="idef9t">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i7jull">
        
      <b className="" id="">
        
      <span className="" id="i2bxqs">
        <span> Cambios en esta Política de Privacidad</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="i590rs">
        
      <span className="" id="ijnz2f">
        <span>Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Las actualizaciones serán publicadas en esta página con una nueva fecha de vigencia. Le recomendamos que revise periódicamente esta página para estar al tanto de cualquier cambio en nuestra política de privacidad.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ihz4mj">
        
      <span className="" id="i3htlx">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="id88lf">
        
      <b className="" id="">
        
      <span className="" id="iasyof">
        <span> Consentimiento</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="iyoe5s">
        
      <span className="" id="iw5ram">
        <span>Al utilizar nuestro Sitio, usted acepta nuestra política de privacidad.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Ppoliticas);
  
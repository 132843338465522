
import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './cart.scss';






const Pcart = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  
  
  

  
  return (
    <div id="id64f0fdff9255464525649d15" >

        <a
          target=""
          className=""
          id="i2rp5q"
          href="/"
        >
          
      <img
        className=""
        id="i3u81m"
        src="https://assetsprojects.s3.amazonaws.com/405opfilm15nbvb.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i8j5" className="">
      
        <a
          target=""
          className=""
          id="idxbx"
          href="/"
        >
          
      <img
        className=""
        id="i3vl"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="iieo" className="">
      
      <div id="inhj1o" className="">
      
      <div id="i55slq" className="">
      
      <div id="ihi59j" className="">
      
      <div id="iez61t" className="">
      
      <div id="i2fbp3" className="">
      
      </div>
      
        <div className="" id="ilflzm">
          
      <div id="i9psvg" className="">
      <span>
         {{ /* name */ }}
        </span>
      </div>
      
        </div>
        
        <div className="" id="isvucr">
          
      <p className="" id="i4rmes">
        <span>L.</span>
      </p>
      
      <div id="ivizn6" className="">
      <span>
         {{ /* price */ }}
        </span>
      </div>
      
        </div>
        
        <div className="" id="inx4va">
          
      <div id="" className="">
      <span>
         {{ /* pricediscount */ }}
        </span>
      </div>
      
        </div>
        
        <div className="" id="igirxj">
          
      <div id="" className="">
      <span>
        {{ /* loftyOriginalName */ }}
      </span>
      </div>
      
        </div>
        
        <div className="" id="iya314">
          
      <div id="" className="">
      <span>
        {{ /* loftyFileName */ }}
      </span>
      </div>
      
        </div>
        
        <div className="" id="i7r7d7">
          
      <div id="" className="">
      <span>
        {{ /* loftyUrl */ }}
      </span>
      </div>
      
        </div>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iffm5" className="gjs-row ">
      
      <div id="i59nf" className="gjs-cell ">
      
      <div id="id0p7" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      <span className="checkmark " id="">
        
      </span>
      
      </label>
      
      <div id="i6ikd" className="">
      
      <img
        className=""
        id="i58ml"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      <div id="" className="checkboxMain ">
      
      </div>
      
      </div>
      
      <div id="izk0k" className="">
      
      <p className="" id="incag">
        
      <b className="" id="ivprg">
        <span>Camiseta Polo</span>
      </b>
      
      </p>
      
      <p className="" id="i2o52">
        
      <b className="" id="">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="itg1bi" className="gjs-cell ">
      
      <div id="ii2ldn" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      <span className="checkmark " id="">
        
      </span>
      
      </label>
      
      <div id="inx618" className="">
      
      <img
        className=""
        id="ikgm9b"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      <div id="" className="checkboxMain ">
      
      </div>
      
      </div>
      
      <div id="iaxmyu" className="">
      
      <p className="" id="iyy0lj">
        
      <b className="" id="">
        <span>Camiseta Polo</span>
      </b>
      
      </p>
      
      <p className="" id="i02yeo">
        
      <b className="" id="">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ibnirr" className="gjs-cell ">
      
      <div id="imwgem" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      <span className="checkmark " id="">
        
      </span>
      
      </label>
      
      <div id="i10zo8" className="">
      
      <img
        className=""
        id="inoig9"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      <div id="" className="checkboxMain ">
      
      </div>
      
      </div>
      
      <div id="iyz5nz" className="">
      
      <p className="" id="icynvk">
        
      <b className="" id="">
        <span>Camiseta Polo</span>
      </b>
      
      </p>
      
      <p className="" id="i0nkhd">
        
      <b className="" id="">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iqwcpl" className="gjs-cell ">
      
      <div id="ig6h2n" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      <span className="checkmark " id="">
        
      </span>
      
      </label>
      
      <div id="if74x4" className="">
      
      <img
        className=""
        id="ijef6h"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      <div id="" className="checkboxMain ">
      
      </div>
      
      </div>
      
      <div id="ictadg" className="">
      
      <p className="" id="i70d21">
        
      <b className="" id="">
        <span>Camiseta Polo</span>
      </b>
      
      </p>
      
      <p className="" id="ix9koc">
        
      <b className="" id="">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i0zwl" className="gjs-cell ">
      
      <div id="ijuau" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      <span className="checkmark " id="">
        
      </span>
      
      </label>
      
      <div id="illfe" className="">
      
      <img
        className=""
        id="i2239"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      <div id="" className="checkboxMain ">
      
      </div>
      
      </div>
      
      <div id="iskb1" className="">
      
      <p className="" id="iyqs7">
        
      <b className="" id="">
        <span>Camiseta Polo</span>
      </b>
      
      </p>
      
      <p className="" id="iowxl">
        
      <b className="" id="">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ib48s" className="gjs-cell ">
      
      <div id="imvzg" className="">
      
      <label id="" className="checkboxContainer ">
      
      <input
        name=""
        type="checkbox"
        placeholder=""
        className="formCheckbox "
        id=""
      />
      
      <span className="checkmark " id="">
        
      </span>
      
      </label>
      
      <div id="igkex" className="">
      
      <img
        className=""
        id="i1232"
        src="https://assetsprojects.s3.amazonaws.com/405opfillzoab7i.png"
        alt="undefined"
      />
      
      <div id="" className="checkboxMain ">
      
      </div>
      
      </div>
      
      <div id="ixcmz" className="">
      
      <p className="" id="iaumf">
        
      <b className="" id="">
        <span>Camiseta Polo</span>
      </b>
      
      </p>
      
      <p className="" id="iavja">
        
      <b className="" id="">
        <span>L. 499.00</span>
      </b>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i29ru" className="">
      
      <div id="ihow92" className="">
      
      <div id="i4q4qw" className="">
      
      <div id="irxlfd" className="">
      
      <p className="" id="i0o2rh">
        
      <b className="" id="iztzok">
        <span>Subtotal:</span>
      <br className="" id="" />
      <span>L. 2994.00</span>
      <br className="" id="" />
      
      </b>
      
      </p>
      
      <p className="" id="ibwcdk">
        
      <b className="" id="i4lpu8">
        <span>Envío:</span>
      <br className="" id="" />
      <span>L. 50.00</span>
      <br className="" id="" />
      
      </b>
      
      </p>
      
      <p className="" id="iddz9v">
        
      <b className="" id="il3egh">
        <span>Subtotal:</span>
      <br className="" id="" />
      <span>L. 3044.00</span>
      <br className="" id="" />
      
      </b>
      
      </p>
      
      </div>
      
      <div id="if9all" className="">
      
      </div>
      
        <a
          target=""
          className=""
          id="i45gtk"
          href={`/buy`}
        >
          
      <div id="if643m" className="">
      
      <p className="" id="iu364h">
        
      <b className="" id="">
        <span>COMPRAR</span>
      </b>
      
      </p>
      
      </div>
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pcart);
  

import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {createstore } from '../../api/store.api'

import './formcompany.scss';






const Pformcompany = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);

  
  
  
  

  if (!loftytoken) {
		return (<div>Requiere Autenticación</div>);
	}

  return (
    <div id="id651b4135925546452564e0a9" >

        <a
          target=""
          className=""
          id="i5127f"
          href="/"
        >
          
      <img
        className=""
        id="i6a797"
        src="https://assetsprojects.s3.amazonaws.com/405opfilm15nbvb.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i8j5" className="">
      
        <a
          target=""
          className=""
          id="idxbx"
          href="/"
        >
          
      <img
        className=""
        id="i3vl"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="ij65" className="">
      
      <div id="i0njf" className="">
      
      <div id="ix7n" className="">
      
      <p className="" id="irt2n">
        <span>Formulario de inscripción</span>
      </p>
      
      <div id="i5u8d" className="">
      
      </div>
      
      <div id="iosji" className="">
      
      <div id="ir27j" className="">
      
        <div className="" id="iubm3">
          
      <form
        id="iqjci"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					const loftyFile = e.target.fileBucket.files[0];
			createstore({
						name: e.target.name.value,
						country: e.target.country.value,
						state: e.target.state.value,
						city: e.target.city.value,
						address: e.target.address.value,
						rtn: e.target.rtn.value,
						userid: loftyuser?._id,
			});
					alert("Se agrego de manera exitosa el registro");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="i4d28" className="">
      
      <label id="izyig" className="">
      <span>Nombre</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="name"
        type="text"
        placeholder="Nombre"
        className="input-mixclo "
        id="imzvy"
      />
      
      </div>
      
      <div id="irvnj" className="">
      
      <label id="" className="">
      <span>País</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="country"
        type="text"
        placeholder="País"
        className="input-mixclo "
        id="ifqcq"
      />
      
      </div>
      
      <div id="ilnv6" className="">
      
      <label id="" className="">
      <span>Departamento</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="state"
        type="text"
        placeholder="Departamento"
        className="input-mixclo "
        id="i6nei"
      />
      
      </div>
      
      <div id="iow9x" className="">
      
      <label id="" className="">
      <span>Ciudad</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="city"
        type="text"
        placeholder="Ciudad"
        className="input-mixclo "
        id="i8774"
      />
      
      </div>
      
      <div id="i704q" className="">
      
      <label id="" className="">
      <span>Direccion</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="address"
        type="text"
        placeholder="Direccion"
        className="input-mixclo "
        id="imkvk"
      />
      
      </div>
      
      <div id="i1853" className="">
      
      <label id="" className="">
      <span>RTN</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="rtn"
        type="text"
        placeholder="RTN"
        className="input-mixclo "
        id="icwmr"
      />
      
      </div>
      
      <div id="iy6g8" className="">
      
      <label id="ics7l" className="">
      <span>Logo de la empresa</span>
      </label>
      
      <br className="" id="" />
      
      <input
        name="fileBucket"
        type="file"
        placeholder=""
        className=""
        id="imsjz"
      />
      
      </div>
      
      <div id="idfmc" className="">
      
      <button
        type="submit"
        name=""
        id="iwmpg"
        className=""
        
      >
      
      <div id="ifiz5" className="">
      <span>Agregar</span>
      </div>
      
      </button>
      
      </div>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pformcompany);
  
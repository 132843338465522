
import React from 'react';

import {connect} from 'react-redux';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './terminoscondiciones.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pterminoscondiciones = ({
  match,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


  
  
  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id6542de8ec239522da2e3ac60" >

      <div id="i25m" className="">
      
      <div id="iq9l" className="">
      
        <a
          target=""
          className=""
          id="ify7b"
          href="#"
        >
          
      <img
        className=""
        id="iss9f"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrc0oo.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i8eor" className="">
      
        <a
          target=""
          className=""
          id="iucyx"
          href="#"
        >
          
      <img
        className=""
        id="i73es"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcriat3.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="i9p3l" className="">
      
        <a
          target=""
          className=""
          id="ijvrd"
          href="#"
        >
          
      <img
        className=""
        id="il6cc"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrlf8y.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="iq338"
          href="#"
        >
          
      <img
        className=""
        id="i9sfh"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrlnfs.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="i76os"
          href="#"
        >
          
      <img
        className=""
        id="i9obh"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrlxk6.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      <div id="iyz7i" className="">
      
        <a
          target=""
          className=""
          id="irruz"
          href="#"
        >
          
      <img
        className=""
        id="ixp2y"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcrmopk.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="ib43" className="">
      
      <div id="i1au" className="">
      
      <div id="ihmgh" className="">
      
        <a
          target=""
          className=""
          id="ig0z1"
          href="/signin"
        >
          
      <img
        className=""
        id="ivg2s"
        src="https://assetsprojects.s3.amazonaws.com/405opfillcs69zg.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="ikbwp"
          href="#"
        >
          
        </a>
        
      <div id="i8iqk" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>About</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Team</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Contact</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Twitter</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ievj" className="">
      
      <div id="ivoo4" className="">
      
      <p className="" id="ipcv9">
        <span>Terminos y Condiciones</span>
      </p>
      
      <p className="" id="isbjh">
        
      <p className="MsoNormal " id="ixi4a">
        
      <span className="" id="iuapg">
        <span>Al visitar nuestro sitio y/o comprar algo de nosotros, participas en nuestro “Servicio” y aceptas los siguientes términos y condiciones (“Términos de Servicio”, “Términos”), incluidos todos los términos y condiciones adicionales y las políticas a las que se hace referencia en el presente documento y/o disponible a través de hipervínculos. Estas Condiciones de Servicio se aplican a todos los usuarios del sitio, incluyendo sin limitación a usuarios que sean navegadores, proveedores, clientes, comerciantes, y/o colaboradores de contenido.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ifj89">
        
      <span className="" id="inpjt">
        <span>Por favor, lee estos Términos de Servicio cuidadosamente antes de acceder o utilizar nuestro sitio web. Al acceder o utilizar cualquier parte del sitio, estás aceptando los Términos de Servicio. Si no estás de acuerdo con todos los términos y condiciones de este acuerdo, entonces no deberías acceder a la página web o usar cualquiera de los servicios. Si las Términos de Servicio son considerados una oferta, la aceptación está expresamente limitada a estos Términos de Servicio.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i6qs2">
        
      <span className="" id="ie7li">
        <span>Cualquier función nueva o herramienta que se añadan a la tienda actual, también estarán sujetas a los Términos de Servicio. Puedes revisar la versión actualizada de los Términos de Servicio, en cualquier momento en esta página. Nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de los Términos de Servicio mediante la publicación de actualizaciones y/o cambios en nuestro sitio web. Es tu responsabilidad chequear esta página periódicamente para verificar cambios. Tu uso continuo o el acceso al sitio web después de la publicación de cualquier cambio constituye la aceptación de dichos cambios.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iagil">
        
      <b className="" id="">
        
      <span className="" id="i6bud">
        <span>Sección 1 – Términos de la tienda en línea</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="im80i">
        
      <span className="" id="ic922">
        <span>Es posible que necesite su propia cuenta de Mixclo Style para utilizar determinados Servicios de Mixclo Style, y es posible que tenga que iniciar sesión en la cuenta y tener un método de pago válido asociado a ella. Si hay algún problema con el método de pago que seleccionó, es posible que apliquemos el cargo a cualquier otro método de pago válido asociado con su cuenta. Visite Sus pagos para administrar sus opciones de pago. Usted es responsable de mantener la confidencialidad de su cuenta y contraseña, y de restringir el acceso a su cuenta, y usted acepta la responsabilidad de todas las actividades que se realicen con su cuenta o con su contraseña. Mixclo Style comercializa productos para menores de edad, pero los vende a los adultos, quienes pueden adquirirlos con una tarjeta de crédito u otro método de pago autorizado. Si usted es menor de 18 años, puede utilizar los Servicios de Mixclo Style solo con la supervisión de un padre o tutor. Así mismo, </span>
      </span>
      
      <span className="" id="iqel7">
        <span>Mixclo Style se reserva el derecho de negar el servicio, cerrar cuentas, cancelar sus derechos de uso de los Servicios de Mixclo Style, eliminar o editar contenido o cancelar pedidos a su entera discreción.</span>
      </span>
      
      <span className="" id="icn1x">
        
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i1l853">
        
      <span className="" id="iw71qg">
        <span>No puedes usar nuestros productos con ningún propósito ilegal o no autorizado tampoco puedes, en el uso del Servicio, violar cualquier ley en tu jurisdicción (incluyendo, pero no limitado a las leyes de derecho de autor).</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iy78en">
        
      <span className="" id="ifp4lc">
        <span>No debes transmitir gusanos, virus o cualquier código de naturaleza destructiva.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ip0q65">
        
      <span className="" id="ir3g8w">
        <span>El incumplimiento o violación de cualquiera de estos Términos darán lugar al cese inmediato de tus Servicios.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iw24qv">
        
      <span className="" id="ixeaqj">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i9wfrd">
        
      <b className="" id="">
        
      <span className="" id="iiac4f">
        <span>Sección 2 – Condiciones generales</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="izt3lb">
        
      <span className="" id="iz4mg9">
        <span>Nos reservamos el derecho de rechazar la prestación de servicio a cualquier persona, por cualquier motivo y en cualquier momento.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iox35k">
        
      <span className="" id="injgjh">
        <span>Entiendes que tu contenido (sin incluir la información de tu tarjeta de crédito), puede ser transferida sin encriptar e involucrar (a) transmisiones a través de varias redes; y (b) cambios para ajustarse o adaptarse a los requisitos técnicos de conexión de redes o dispositivos. La información de tarjetas de crédito está siempre encriptada durante la transferencia a través de las redes.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i55s1w">
        
      <span className="" id="ifw4ab">
        <span>Estás de acuerdo con no reproducir, duplicar, copiar, vender, revender o explotar cualquier parte del Servicio, uso del Servicio, o acceso al Servicio o cualquier contacto en el sitio web a través del cual se presta el servicio, sin el expreso permiso por escrito de nuestra parte.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i19f08">
        
      <span className="" id="i2n0re">
        <span>Los títulos utilizados en este acuerdo se incluyen solo por conveniencia y no limita o afecta a estos Términos.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i0fu5a">
        
      <span className="" id="i6vhev">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i07t45">
        
      <b className="" id="">
        
      <span className="" id="igtg6f">
        <span>Sección 4 – Modificaciones al servicio y precios</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="ihy0yl">
        
      <span className="" id="ivim9n">
        <span>Los precios de nuestros productos están sujetos a cambio sin aviso.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iloe3g">
        
      <span className="" id="i95mda">
        <span>Nos reservamos el derecho de modificar o discontinuar el Servicio (o cualquier parte del contenido) en cualquier momento sin aviso previo.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ii2wbk">
        
      <span className="" id="ih1jjx">
        <span>No seremos responsables ante ti o alguna tercera parte por cualquier modificación, cambio de precio, suspensión o discontinuidad del Servicio.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i5w27f">
        
      <span className="" id="it2uco">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ip8y4l">
        
      <b className="" id="">
        
      <span className="" id="inuznw">
        <span>Sección 5 – Productos o servicios</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="i8t8v7">
        
      <span className="" id="iiw8do">
        <span>Los productos o servicios están disponibles exclusivamente en línea a través del sitio web. Estos productos o servicios pueden tener cantidades limitadas y estar sujetas a devolución o cambio de acuerdo a nuestra política de devolución solamente.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ieh9uh">
        
      <span className="" id="ipcz98">
        <span>Hemos hecho el esfuerzo de mostrar los colores y las imágenes de nuestros productos, en la tienda, con la mayor precisión de colores posible. No podemos garantizar que el monitor de tu computadora muestre los colores de manera exacta.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iyxjaj">
        
      <span className="" id="istizs">
        <span>Todos los productos son sujetos a disponibilidad. Las imágenes en display son de carácter ilustrativo. Estos legales cubren todas las publicaciones hechas en todos los medios.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i50ix2">
        
      <span className="" id="i10xeh">
        <span>Todas las promociones no son acumulables con otras promociones. Aplican hasta agotar existencias y están restringidas a una por persona.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i7rtkz">
        
      <span className="" id="i74i7i">
        <span>Nos reservamos el derecho, pero no estamos obligados, para limitar las ventas de nuestros productos o servicios a cualquier persona, región geográfica o jurisdicción. Podemos ejercer este derecho basados en cada caso. Nos reservamos el derecho de limitar las cantidades de los productos o servicios que ofrecemos. Todas las descripciones de productos o precios de los productos están sujetos a cambios en cualquier momento sin previo aviso, a nuestra sola discreción. Nos reservamos el derecho de discontinuar cualquier producto en cualquier momento. Cualquier oferta de producto o servicio hecho en este sitio es nula donde esté prohibido.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ibeuis">
        
      <span className="" id="icz66u">
        <span>No garantizamos que la calidad de los productos, servicios, información u otro material comprado u obtenido por ti cumpla con tus expectativas, o que cualquier error en el Servicio será corregido.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iiituk">
        
      <span className="" id="i0w1lq">
        <span>Mixclo Style se compromete a proporcionar los mejores productos que cumpla con nuestros estándares de aseo, así como cumplir con la más alta seguridad de la salud y los requisitos reglamentarios aplicables relacionados. En el caso de que alguno de nuestros productos no cumpla con los más altos estándares, no nos hacemos responsables de los daños resultantes de la falta de uso o defecto de los productos, y se retirará el producto de nuestro mercado de inmediato.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ivzylu">
        
      <span className="" id="ia47dx">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i8npcr">
        
      <b className="" id="">
        
      <span className="" id="ir0kzc">
        <span>Sección 6 – Exactitud de facturación e información de cuenta</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="iiisec">
        
      <span className="" id="i41pch">
        <span>Nos reservamos el derecho de rechazar cualquier pedido que realice con nosotros. Podemos, a nuestra discreción, limitar o cancelar las cantidades compradas por persona, por hogar o por pedido. Estas restricciones pueden incluir pedidos realizados por o bajo la misma cuenta de cliente, la misma tarjeta de crédito, y/o pedidos que utilizan la misma facturación y/o dirección de envío.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iqztak">
        
      <span className="" id="iy33x8">
        <span>Precios pueden variar sin previo aviso debido a factores externos: devaluaciones monetarias, alteraciones drásticas en el tipo de cambio, entre otras.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iprrnj">
        
      <span className="" id="ic6qr3">
        <span>En el caso de que hagamos un cambio o cancelemos una orden, podemos intentar notificarle poniéndonos en contacto vía correo electrónico y/o dirección de facturación / número de teléfono proporcionado en el momento en que se hizo el pedido. Nos reservamos el derecho de limitar o prohibir las órdenes que, a nuestro juicio, parecen ser colocado por los concesionarios, revendedores o distribuidores.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i1yvux">
        
      <span className="" id="ijs471">
        <span>Te comprometes a proporcionar información actual, completa y precisa de la compra y cuenta utilizada para todas las compras realizadas en nuestra tienda. Te comprometes a actualizar rápidamente tu cuenta y otra información, incluyendo tu dirección de correo electrónico y números de tarjetas de crédito y fechas de vencimiento, para que podamos completar tus transacciones y contactarte cuando sea necesario.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="idp8w4">
        
      <span className="" id="ig3hr1">
        <span>Para más detalles, por favor revisa nuestra Política de Devoluciones, en la sección 18 de este documento.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i4zo77">
        
      <span className="" id="i6h71m">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iaf462">
        
      <b className="" id="">
        
      <span className="" id="i507ok">
        <span>Sección 9 – Comentarios de usuarios, captación y otros envíos</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="ijcnky">
        
      <span className="" id="iongll">
        <span>Si a pedido nuestro, envías ciertas presentaciones específicas (por ejemplo, la participación en concursos) o sin un pedido de nuestra parte envías ideas creativas, sugerencias, proposiciones, planes, u otros materiales, ya sea en línea, por email, por correo postal, o de otra manera (colectivamente, ‘comentarios’), aceptas que podamos, en cualquier momento, sin restricción, editar, copiar, publicar, distribuir, traducir o utilizar por cualquier medio comentarios que nos hayas enviado. No tenemos ni tendremos ninguna obligación (1) de mantener ningún comentario confidencialmente; (2) de pagar compensación por comentarios; o (3) de responder a comentarios.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iiiobg">
        
      <span className="" id="ihp7tv">
        <span>Nosotros podemos, pero no tenemos obligación de, monitorear, editar o remover contenido que consideremos sea ilegítimo, ofensivo, amenazante, calumnioso, difamatorio, obsceno u objetable o viole la propiedad intelectual de cualquiera de las partes o los Términos de Servicio.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iitz9h">
        
      <span className="" id="ikwamk">
        <span>Aceptas que tus comentarios no violarán los derechos de terceras partes, incluyendo derechos de autor, marca, privacidad, personalidad u otro derecho personal, o de propiedad. Asimismo, aceptas que tus comentarios no contienen material difamatorio o ilegal, abusivo u obsceno, o contienen virus informáticos u otro malware que pudiera, de alguna manera, afectar el funcionamiento del Servicio o de cualquier sitio web relacionado. No puedes utilizar una dirección de correo electrónico falsa, usar otra identidad que no sea legítima, o engañar a terceras partes o a nosotros en cuanto al origen de tus comentarios. Tu eres el único responsable por los comentarios que haces y su precisión. No nos hacemos responsables y no asumimos ninguna obligación con respecto a los comentarios publicados por ti o cualquier tercer parte.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ip2cul">
        
      <span className="" id="iu6hyg">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ii6uoq">
        
      <b className="" id="">
        
      <span className="" id="ibokmi">
        <span>Sección 10 – Información personal</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="ijn1c3">
        
      <span className="" id="iw17ix">
        <span>Tu presentación de información personal a través del sitio se rige por nuestra Política de Privacidad. Para ver nuestro Aviso de Privacidad.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="icbmug">
        
      <span className="" id="i4az4n">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iknlej">
        
      <b className="" id="">
        
      <span className="" id="i50znt">
        <span>Sección 11 – Errores, inexactitudes y omisiones</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="ifvy5q">
        
      <span className="" id="iw0u8w">
        <span>De vez en cuando puede haber información en nuestro sitio o en el Servicio que contiene errores tipográficos, inexactitudes u omisiones que puedan estar relacionadas con las descripciones de productos, precios, promociones, ofertas, gastos de envío del producto y la disponibilidad. Nos reservamos el derecho de corregir los errores, inexactitudes u omisiones y de cambiar o actualizar la información o cancelar pedidos si alguna información en el Servicio o en el sitio web es inexacta en cualquier momento sin previo aviso (incluso después de que hayas enviado tu orden).</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i60nuz">
        
      <b className="" id="">
        
      <span className="" id="irmo0n">
        <span>Sección 12 – Usos prohibidos</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="igdvb5">
        
      <span className="" id="iekvhn">
        <span>En adición a otras prohibiciones como se establece en los Términos de Servicio, se prohíbe el uso del sitio o su contenido: (a) para ningún propósito ilegal; (b) para pedirle a otros que realicen o participen en actos ilícitos; (c) para violar cualquier regulación, reglas, leyes internacionales, federales, provinciales o estatales, u ordenanzas locales; (d) para infringir o violar el derecho de propiedad intelectual nuestro o de terceras partes; (e) para acosar, abusar, insultar, dañar, difamar, calumniar, desprestigiar, intimidar o discriminar por razones de género, orientación sexual, religión, etnia, raza, edad, nacionalidad o discapacidad; (f) para presentar información falsa o engañosa; (g) para cargar o transmitir virus o cualquier otro tipo de código malicioso que sea o pueda ser utilizado en cualquier forma que pueda comprometer la funcionalidad o el funcionamiento del Servicio o de cualquier sitio web relacionado, otros sitios o Internet; (h) para recopilar o rastrear información personal de otros; (i) para generar spam, phish, pharm, pretext, spider, crawl, or scrape; (j) para cualquier propósito obsceno o inmoral; o (k) para interferir con o burlar los elementos de seguridad del Servicio o cualquier sitio web relacionado otros sitios o Internet. Nos reservamos el derecho de suspender el uso del Servicio o del sitio web por violar cualquiera de los ítems de los usos prohibidos.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i6euts">
        
      <span className="" id="izdx2a">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="itgqxe">
        
      <b className="" id="">
        
      <span className="" id="iq5z6y">
        <span>Sección 13 – Exclusión de garantías; limitación de responsabilidad</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="innn0l">
        
      <span className="" id="ibzmwh">
        <span>No garantizamos ni aseguramos que el uso de nuestro servicio será ininterrumpido, puntual, seguro o libre de errores.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i6wp9r">
        
      <span className="" id="iwh7zg">
        <span>No garantizamos que los resultados que se puedan obtener del uso del servicio serán exactos o confiables.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i138fx">
        
      <span className="" id="iei2xc">
        <span>Aceptas que de vez en cuando podemos quitar el servicio por períodos de tiempo indefinidos o cancelar el servicio en cualquier momento sin previo aviso.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="icdxcy">
        
      <span className="" id="itwmnb">
        <span>Aceptas expresamente que el uso de, o la posibilidad de utilizar, el servicio es bajo tu propio riesgo. El servicio y todos los productos y servicios proporcionados a través del servicio son (salvo lo expresamente manifestado por nosotros) proporcionados «tal cual» y «según esté disponible» para su uso, sin ningún tipo de representación, garantías o condiciones de ningún tipo, ya sea expresa o implícita, incluidas todas las garantías o condiciones implícitas de comercialización, calidad comercializable, la aptitud para un propósito particular, durabilidad, título y no infracción.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ipxdcb">
        
      <span className="" id="ikw566">
        <span>En ningún caso Mixclo Style, nuestros directores, funcionarios, empleados, afiliados, agentes, contratistas, internos, proveedores, prestadores de servicios o licenciantes serán responsables por cualquier daño, pérdida, reclamo, o daños directos, indirectos, incidentales, punitivos, especiales o consecuentes de cualquier tipo, incluyendo, sin limitación, pérdida de beneficios, pérdida de ingresos, pérdida de ahorros, pérdida de datos, costos de reemplazo, o cualquier daño similar, ya sea basado en contrato, agravio (incluyendo negligencia), responsabilidad estricta o de otra manera, como consecuencia del uso de cualquiera de los servicios o productos adquiridos mediante el servicio, o por cualquier otro reclamo relacionado de alguna manera con el uso del servicio o cualquier producto, incluyendo pero no limitado, a cualquier error u omisión en cualquier contenido, o cualquier pérdida o daño de cualquier tipo incurridos como resultados de la utilización del servicio o cualquier contenido (o producto) publicado, transmitido, o que se pongan a disposición a través del servicio, incluso si se avisa de su posibilidad.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i2rizu">
        
      <span className="" id="imdsdp">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i101dh">
        
      <b className="" id="">
        
      <span className="" id="ios35h">
        <span>Sección 14 – Indemnización</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="i4cjsl">
        
      <span className="" id="i6b1ug">
        <span>Aceptas indemnizar, defender y mantener indemne a Mixclo Style y nuestras matrices, subsidiarias, afiliados, socios, funcionarios, directores, agentes, contratistas, concesionarios, proveedores de servicios, subcontratistas, proveedores, internos y empleados, de cualquier reclamo o demanda, incluyendo honorarios razonables de abogados, hechos por cualquier tercero a causa o como resultado de tu incumplimiento de las Condiciones de Servicio o de los documentos que incorporan como referencia, o la violación de cualquier ley o de los derechos de un tercero.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ie9c1d">
        
      <span className="" id="i6qizo">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i2cjg5">
        
      <b className="" id="">
        
      <span className="" id="i6gxw2">
        <span>Sección 15 – Divisibilidad</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="ignccc">
        
      <span className="" id="ittoxe">
        <span>En el caso de que se determine que cualquier disposición de estas Condiciones de Servicio sea ilegal, nula o inejecutable, dicha disposición será, no obstante, efectiva a obtener la máxima medida permitida por la ley aplicable, y la parte no exigible se considerará separada de estos Términos de Servicio, dicha determinación no afectará la validez de aplicabilidad de las demás disposiciones restantes.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="idmoiq">
        
      <span className="" id="i1ml5r">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i53w0n">
        
      <b className="" id="">
        
      <span className="" id="ibq0l9">
        <span>Sección 16 – Rescisión</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="ia6ysc">
        
      <span className="" id="ia754b">
        <span>Las obligaciones y responsabilidades de las partes que hayan incurrido con anterioridad a la fecha de terminación sobrevivirán a la terminación de este acuerdo a todos los efectos.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iyc0jd">
        
      <span className="" id="ilqg02">
        <span>Estas Condiciones de servicio son efectivos a menos que y hasta que sea terminado por ti o nosotros. Puedes terminar estos Términos de Servicio en cualquier momento por avisarnos que ya no deseas utilizar nuestros servicios, o cuando dejes de usar nuestro sitio.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="igll7q">
        
      <span className="" id="ic4wyj">
        <span>Si a nuestro juicio, fallas, o se sospecha que has fallado, en el cumplimiento de cualquier término o disposición de estas Condiciones de Servicio, también podemos terminar este acuerdo en cualquier momento sin previo aviso, y seguirás siendo responsable de todos los montos adeudados hasta incluida la fecha de terminación; y/o en consecuencia podemos negar el acceso a nuestros servicios (o cualquier parte del mismo).</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i1oy9c">
        
      <span className="" id="ijbmae">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="iss0sl">
        
      <b className="" id="">
        
      <span className="" id="icrkql">
        <span>Sección 17 – Acuerdo completo</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="i3r5ba">
        
      <span className="" id="in4jgz">
        <span>Estas Condiciones del servicio y las políticas o reglas de operación publicadas por nosotros en este sitio o con respecto al servicio constituyen el acuerdo completo y el entendimiento entre tú y nosotros y rigen el uso del Servicio y reemplaza cualquier acuerdo, comunicaciones y propuestas anteriores o contemporáneas, ya sea oral o escrita, entre tu y nosotros (incluyendo, pero no limitado a, cualquier versión previa de los Términos de Servicio).</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i36jeh">
        
      <span className="" id="i0e6vh">
        <span>Cualquier ambigüedad en la interpretación de estas Condiciones del servicio no se interpretarán en contra del grupo de redacción.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ijsm9g">
        
      <span className="" id="i9vnz9">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ie8k03">
        
      <b className="" id="">
        
      <span className="" id="i9ciiy">
        <span>Sección 18 – Política de devoluciones</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="ijq6h2">
        
      <span className="" id="i1924h">
        <span>Cualquier reclamo de garantía deberá realizarse dentro de los sesenta (60) días siguientes a la recepción del PRODUCTO. Para efectos de realizar el reclamo de garantía, el USUARIO deberá presentarse a cualquiera de las sucursales físicas de los COMERCIO AFILIADO en el que realizó la compra y deberá presentar una copia impresa de la factura o comprobante de la compra solicitada a través de la PLATAFORMA.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ipyevm">
        
      <span className="" id="ivvx5g">
        <span>Condiciones del producto</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ih9khk">
        
      <span className="" id="i92lqi">
        <span>El producto deberá devolverse en óptimas condiciones, sin rastros de haber sido utilizado, con las etiquetas originales o en su defecto, si ya fueron retiradas, debes introducirlas en el empaque.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ihsul4">
        
      <b className="" id="">
        
      <span className="" id="ik7c7f">
        <span>Sección 19 – Ley</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="i1g2dg">
        
      <span className="" id="iaarbg">
        <span>Para la interpretación y cumplimiento de los presentes términos y condiciones, las partes se someten a la jurisdicción de los tribunales de la República de Honduras.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ied1wx">
        
      <span className="" id="iceg14">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="ilgddl">
        
      <span className="" id="iarhq7">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="io7kdc">
        
      <span className="" id="iplwzy">
        
      </span>
      
      </p>
      
      <p className="MsoNormal " id="i7iqjb">
        
      <b className="" id="">
        
      <span className="" id="iqkqg8">
        <span>Sección 20 – Cambios en los términos de servicio</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </b>
      
      </p>
      
      <p className="MsoNormal " id="irlgzp">
        
      <span className="" id="iztcjz">
        <span>Puedes revisar la versión más actualizada de los Términos de Servicio en cualquier momento en esta página.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      <p className="MsoNormal " id="izr985">
        
      <span className="" id="ihb95d">
        <span>Nos reservamos el derecho, a nuestra sola discreción, de actualizar, modificar o reemplazar cualquier parte de estas Condiciones del servicio mediante la publicación de las actualizaciones y los cambios en nuestro sitio web. Es tu responsabilidad revisar nuestro sitio web periódicamente para verificar los cambios. El uso continuo de o el acceso a nuestro sitio Web o el Servicio después de la publicación de cualquier cambio en estas Condiciones de servicio implica la aceptación de dichos cambios.</span>
    <div id="" className="">
    
    </div>
    
      </span>
      
      </p>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pterminoscondiciones);
  